'use strict';

import React from 'react';

const LoadingScreen = ({ err = false, message = `An error occured while loading this data. Please try again.`, loadingMessage = `` }) => (
  err ? (
    <div className="loading-screen">
      <div className="error">
        <span>{message}</span>
      </div>
    </div>
  ) : (
      <div className="loading-screen">
        <div className="is-loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div>{loadingMessage}</div>
      </div>
    )
);

export default LoadingScreen;
