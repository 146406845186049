'use strict';

import React from 'react';
import Close from '../../svgs/Close.jsx';
import CheckMark from '../../svgs/CheckMark.jsx';

const Notification = ({ message, success, onClose }) => (
  <div className={ `app-notification ${ success ? `success` : `error` }` } onClick={ onClose }>
    <div className="icon">
      { success ? CheckMark : Close }
    </div>
    <div className="message">
      <span>{ message }</span>
      {
        !success && (
          <small>Check console log for more details.</small>
        )
      }
    </div>
  </div>
);

export default Notification;
