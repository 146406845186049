'use strict';

import { 
  SET_AUTH_DATA
} from '../actions';

const initialState = {
  token: ``,
  refreshToken: ``,
  isAuthed: false
};

export default function auth(state = initialState, action) {
  switch(action.type) {
  case SET_AUTH_DATA:
    return {
      ...state,
      ...action.payload
    };
  default: 
    return state;
  }
}
