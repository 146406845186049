'use strict';

import {
  SET_SITELIST_FILTERS,
  SET_MAP_SHOWN
} from '../actions';

const initialState = {
  filters: [],
  mapsShown: {
    clouds: false,
    precipitation: false,
    pressure: false,
    wind: false,
    temp: false
  }
};

export default function example(state = initialState, action) {
  switch(action.type) {
  case SET_SITELIST_FILTERS:
    return {
      ...state,
      filters: action.payload.slice()
    };
  case SET_MAP_SHOWN: 
    return {
      ...state,
      mapsShown: {
        ...state.mapsShown,
        [action.payload.map]: action.payload.value
      }
    };
  default: 
    return state;
  }
}
