'use strict';

import { 
  SET_SITE_FILTERS,
  SET_VIEW_TYPE
} from '../actions';

const initialState = {
  viewType: `zones`,
  filters: []
};

export default function example(state = initialState, action) {
  switch(action.type) {
  case SET_VIEW_TYPE:
    return {
      ...state,
      viewType: action.payload
    };
  case SET_SITE_FILTERS:
    return {
      ...state,
      filters: action.payload.slice()
    };
  default: 
    return state;
  }
}
