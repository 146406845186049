'use strict';

import React from 'react';
import toaster from 'toasted-notes';
import store from './StoreHelper.js';
import Notification from '../components/utilities/Notification.jsx';

const handleRequestNotification = (status, response, options) => {
  if (!store.getState().settings.global_settings.noisy_requests && (!options.type || options.type == `GET` || status.toString()[0] == `2`)) return;
  if ((!options.type || options.type == `GET` || options.silent_success) && status.toString()[0] == `2`) return;

  let success = false,
    message = options.failure || `Request failed.`;

  if (status.toString()[0] == `2`) success = true;
  if (success) message = options.succeeded || `Request succeeded.`;

  if (!success) {
    /* eslint-disable */
    console.error(status, message, response);
    /* eslint-enable */
  }

  toaster.notify(({ onClose }) => (
    <Notification { ...{ onClose, success, message } } />
  ), {
    position: `bottom`,
    duration: 3000
  });
};

export default handleRequestNotification;
