'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path d="M17.46 22.75H3.16a2.41 2.41 0 0 1-2.41-2.41V6" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M21.57 11.57v4.83a1.81 1.81 0 0 1-1.81 1.81H7.1a1.81 1.81 0 0 1-1.81-1.81V3.74A1.81 1.81 0 0 1 7.1 1.93h4.83" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path fill="none" stroke="currentColor" class="h" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.44.75l3.31 3.31-9.57 9.57H9.87v-3.31L19.44.75z"/>`,
  {
    width: `23.5`,
    height: `23.5`
  }
);
/* eslint-enable */
