'use strict';

import { createStore } from 'redux';
import { persistStore } from 'redux-persist';
import reducer from '../reducers';
import syncSettings from './SettingsSyncHelper.js';

const store = createStore(reducer);

export const persistor = persistStore(store, null, () => syncSettings());

export default store;
