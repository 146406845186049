'use strict';

import React from 'react';
import { $ } from '../../helpers/ApiHelper.js';

const localVersion = process.env.VERSION_TAG;

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      isLatestVersion: false
    };
  }

  refreshCacheAndReload() {
    /* eslint-disable */
    console.log(`Clearing cache and hard reloading...`);
    /* eslint-disable */

    if (window.caches) {
      window.caches.keys().then((names) => {
        for (let name of names) caches.delete(name);
      });
    }

    window.location.reload(true);
  }

  componentDidMount() {
    $({
      url: `/version`,
      success: ({ version }) => {
        /* eslint-disable */
        console.log(`Local version: ${ localVersion }`);
        console.log(`Received version: ${ version }`);
        /* eslint-disable */
    
        this.setState({
          loading: false,
          isLatestVersion: localVersion == `development` || (version === localVersion)
        });
      },
      failure: `Failed to check latest version.`,
      error: () => {
        this.setState({
          loading: false,
          isLatestVersion: true
        });
      }
    }, false, true);
  }

  render() {
    const { loading, isLatestVersion } = this.state;
    const refreshCacheAndReload = this.refreshCacheAndReload.bind(this);

    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}

export default CacheBuster;
