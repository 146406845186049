"use strict";

import store from "./StoreHelper.js";
import Home from "../svgs/Home.jsx";
import Map from "../svgs/Map.jsx";
import Trends from "../svgs/Trends.jsx";
import Notifications from "../svgs/Notifications.jsx";
import Health from "../svgs/Health.jsx";
import MassUpdates from "../svgs/MassUpdates.jsx";
import Wifi from "../svgs/Wifi.jsx";

export const getNavItems = () =>
  [
    {
      icon: Home,
      label: `Home`,
      uri: `/dashboard`,
      enabled: store.getState().features.dashboard,
    },
    {
      icon: Map,
      label: `Sites`,
      uri: `/sites`,
      enabled:
        store.getState().features.sites &&
        (store.getState().features.assets ||
          store.getState().features.controls),
    },
    // {
    //   icon: Health,
    //   label: `Health`,
    //   uri: `/health`,
    //   enabled: store.getState().features.health,
    // },
    {
      icon: Trends,
      label: `Trends`,
      uri: `/trends`,
      enabled: store.getState().features.reports,
    },
    {
      icon: Notifications,
      label: `Notifications`,
      uri: `/rules`,
      enabled: store.getState().features.notifications,
    },
    {
      icon: MassUpdates,
      label: `Mass Edit`,
      uri: `/massedit`,
      enabled: store.getState().features.massedit,
    },
    {
      icon: Wifi,
      label: `Router Status`,
      uri: `/router`,
      enabled: store.getState().features.provisioning,
    },
  ].filter((n) => n.enabled);
