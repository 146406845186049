'use strict';

// Leaving these lines commented for future reference if necessary

// export const formatApiName = (s) => {
//   /* eslint-disable */
//   return s.replace(/([A-Z]+|\d+)/g, (m) => {
//     return ` ${ m }`;
//   }).replace(/^\s/, ``);
//   /* eslint-enable */
// };

export const getBestName = (entity) => {
  if (!entity) return ``;
  // let noformat = false;
  // if (entity.id === entity.site_id) noformat = true;
  if (Object.prototype.hasOwnProperty.call(entity, `display_name`) && entity.display_name && entity.display_name.length > 0) return entity.display_name;
  // if (noformat) return entity.name;
  // return formatApiName(entity.name);
  return entity.name;
};

export const EQUIPMENT_NAME_BLACKLIST = [`metadata`, `gateway`, `schedule`];

export const POINT_DOWN_STATUS = [`fault`, `stale`, `disabled`, `down`, `null`];
