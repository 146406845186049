'use strict';

import React from 'react';

export default (__html, props, className) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox={ `0 0 ${ props.width } ${ props.height }` }
    className={ className }
    width={ props.width }
    height={ props.height }
    dangerouslySetInnerHTML={ { __html } } />
);
