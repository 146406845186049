'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path d="M11.3 3.7c1.5.3 2.7 1.5 3 3m-3-6.1c3.2.4 5.7 2.9 6.1 6.1" fill="none" stroke="currentColor" class="h" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"/><path d="M13.7 10.7V13c0 1.3 1 2.3 2.3 2.3H.6c1.3 0 2.3-1 2.3-2.3V9.2c0-2.6 1.8-4.8 4.3-5.3m2.4 14.5c-.4.7-1.4 1-2.1.6-.2-.1-.4-.3-.6-.6" fill="none" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"/>`,
  {
    width: `18`,
    height: `19.8`
  }
);
/* eslint-enable */
