'use strict';

import { SET_VIEW_DISCONNECT_MSG } from './index.js';

export function setViewDisconnectMsg(b) {
  return {
    type: SET_VIEW_DISCONNECT_MSG,
    payload: b
  };
}
