'use strict';

import { SET_PERMISSIONS } from './index.js';

export function setPermissions(permissions) {  
  return {
    type: SET_PERMISSIONS,
    payload: permissions
  };
}
