'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path d="M11.5.6L.6 11.5M.6.6l10.9 10.9" fill="none" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-miterlimit="10"/>`,
  {
    width: `12.1`,
    height: `12.1`,
    viewBox: `0 0 12.1 12.1`
  }
);
/* eslint-enable */
