'use strict';

import { 
  SET_ASSET_VIEW_TYPE
} from '../actions';

const initialState = {
  viewType: `table`
};

export default function assets(state = initialState, action) {
  switch(action.type) {
  case SET_ASSET_VIEW_TYPE:
    return {
      ...state,
      viewType: action.payload
    };
  default: 
    return state;
  }
}
