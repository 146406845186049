'use strict';

import { 
  SET_REPORT_TYPE,
  SET_REPORT_POINTS,
  SET_REPORT_START,
  SET_REPORT_END,
  SET_QUICK_TREND,
  RESET_REPORT
} from '../actions';

const initialState = {
  type: `line`,
  points: [],
  start: Date.now() - (86400000 * 7),
  end: Date.now()
};

export default function report(state = initialState, action) {
  switch(action.type) {
  case SET_REPORT_TYPE:
    return {
      ...state,
      type: action.payload
    };
  case SET_REPORT_POINTS:
    return {
      ...state,
      points: action.payload
    };
  case SET_REPORT_START: {
    let start = +(new Date(action.payload)),
      end = state.end;

    if (start > end) {
      end = start + 86400000;
    }

    return {
      ...state,
      start,
      end
    };
  }
  case SET_REPORT_END: {
    let start = state.start,
      end = +(new Date(action.payload));

    if (end < start) {
      start = end - 86400000;
    }

    return {
      ...state,
      start,
      end
    };
  }
  case RESET_REPORT:
    return {
      ...state,
      ...initialState
    };
  case SET_QUICK_TREND:
    return {
      ...state,
      points: action.payload.points,
      start: +(new Date(action.payload.start)),
      end: +(new Date(action.payload.end))
    };
  default: 
    return state;
  }
}
