


'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<circle cx="9" cy="9" r="8.9"></circle><path d="M6.09 6a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="9" y1="12" x2="9.02" y2="13"></line>`,
  {
    width: `18`,
    height: `18`
  }
);
/* eslint-enable */
