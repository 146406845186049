'use strict';

import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { getNavItems } from '../../helpers/NavHelper.js';
import Menu from '../../svgs/Menu.jsx';

const MobileNav = ({ location }) => (
  <footer className="mobile-only">
    <ul>
      {
        getNavItems().slice(0,3).map((n, i) => (
          <li key={ i }>
            <NavLink 
              location={ location } 
              to={ n.uri } 
              activeClassName="active">
              <span>{ n.icon }</span>
            </NavLink>
          </li>
        ))
      }
      <li>
        <a title="Show Navigation" onClick={ () => window.showMobileNavigation() }><span>{ Menu }</span></a>
      </li>
    </ul>
  </footer>
);

export default withRouter(MobileNav);
