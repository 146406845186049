'use strict';

const THEME_FOOTPRINT = {
  brand: {
    name: `brand`,
    friendlyName: `brand`
  },
  primary: {
    name: `primary`,
    friendlyName: `primary`,
    variants: {
      adjust: [5],
      altAdjust: [5],
      alpha: [25, 50, 75]
    }
  },
  error: {
    name: `error`,
    friendlyName: `error`,
    variants: {
      alpha: [50]
    }
  },
  orange: {
    name: `orange-`,
    friendlyName: `orange`
  },
  warning: {
    name: `warning`,
    friendlyName: `warning`
  },
  success: {
    name: `success`,
    friendlyName: `success`
  },
  appNavBackground: {
    name: `appNavBackground`,
    friendlyName: `appNavBackground`
  },
  black: {
    name: `black-`,
    friendlyName: `black`,
    variants: {
      alpha: [5, 10, 15, 16, 23, 20, 30, 40, 50, 60, 70, 80, 90]
    }
  },
  darkGrey: {
    name: `darkGrey`,
    friendlyName: `darkGrey`,
    variants: {
      alpha: [16, 20, 23, 80]
    }
  },
  middleGrey: {
    name: `middleGrey`,
    friendlyName: `middleGrey`
  },
  lighterGrey: {
    name: `lighterGrey`,
    friendlyName: `lighterGrey`
  },
  inputBorder: {
    name: `inputBorder`,
    friendlyName: `inputBorder`,
    variants: {
      altAdjust: [5]
    }
  },
  lowImpact: {
    name: `lowImpact`,
    friendlyName: `lowImpact`,
    variants: {
      altAdjust: [5, 50]
    }
  },
  buttonGrey: {
    name: `buttonGrey`,
    friendlyName: `buttonGrey`,
    variants: {
      altAdjust: [5]
    }
  },
  darkerGreyBackground: {
    name: `darkerGreyBackground`,
    friendlyName: `darkerGreyBackground`
  },
  lightBackground: {
    name: `lightBackground`,
    friendlyName: `lightBackground`,
    variants: {
      altAdjust: [2, 4]
    }
  },
  trendGrey: {
    name: `trendGrey`,
    friendlyName: `trendGrey`
  },
  lightestBackground: {
    name: `lightestBackground`,
    friendlyName: `lightestBackground`
  },
  white: {
    name: `white-`,
    friendlyName: `white`,
    variants: {
      altAdjust: [2, 5, 10, 15],
      alpha: [20, 40, 50, 70, 75, 90]
    }
  },
  borderColor: {
    name: `borderColor`,
    friendlyName: `borderColor`
  }
};

export default THEME_FOOTPRINT;
