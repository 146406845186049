'use strict';

export const SET_AUTH_DATA = `SET_AUTH_DATA`;

export const SET_REPORT_TYPE = `SET_REPORT_TYPE`;
export const SET_REPORT_POINTS = `SET_REPORT_POINTS`;
export const SET_QUICK_TREND = `SET_QUICK_TREND`;
export const SET_REPORT_START = `SET_REPORT_START`;
export const SET_REPORT_END = `SET_REPORT_END`;
export const RESET_REPORT = `RESET_REPORT`;
export const SET_PERMISSIONS = `SET_PERMISSIONS`;
export const SET_LOGIN_DETAILS = `SET_LOGIN_DETAILS`;

export const SET_SITELIST_FILTERS = `SET_SITELIST_FILTERS`;
export const SET_SITE_FILTERS = `SET_SITE_FILTERS`;
export const SET_VIEW_TYPE = `SET_VIEW_TYPE`;
export const SET_MAP_SHOWN = `SET_MAP_SHOWN`;

export const UPDATE_GLOBAL_SETTINGS = `UPDATE_GLOBAL_SETTINGS`;
export const UPDATE_CLIENT_SETTINGS = `UPDATE_CLIENT_SETTINGS`;

export const SET_ASSET_VIEW_TYPE = `SET_ASSET_VIEW_TYPE`;

export const SET_VIEW_DISCONNECT_MSG = `SET_VIEW_DISCONNECT_MSG`;
