'use strict';

import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import * as atatus from 'atatus-spa';

// Set up Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from '../helpers/StoreHelper.js';


import App from './App.jsx';
import LoadingScreen from './utilities/LoadingScreen.jsx';
import CacheBuster from './utilities/CacheBuster.jsx';

atatus.config(process.env.ATATUS_KEY, {
  version: process.env.GIT_TAG,
  allowedDomains: [/^((?!staging).)*\.?fsgchariot.app/]
}).install();

store.subscribe(() => {
  let temp = JSON.parse(JSON.stringify(store.getState()));

  delete temp.auth;

  atatus.setCustomData(temp);
});

const Root = () => (
  <CacheBuster>
    {
      ({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return (
          <LoadingScreen />
        );

        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <Provider store={ store }>
            <PersistGate
              loading={ <LoadingScreen /> }
              persistor={ persistor }>
              <BrowserRouter>
                <Route 
                  path="*"
                  component={ App } />
              </BrowserRouter>
            </PersistGate>
          </Provider>
        );
      }
    }
  </CacheBuster>
);

export default Root;
