'use strict';

const THEMES = {
  light: {
    invertToLight: true,
    brand: `#3039b2`,
    primary: `#4040DC`,
    error: `#EF4552`,
    orange: `#F68921`,
    warning: `#F2CC0F`,
    success: `#36b0a7`,
    accent: `#5043F9`,
    black: `#101820`,
    darkGrey: `#313341`,
    middleGrey: `#444354`,
    lighterGrey: `#767582`,
    inputBorder: `#d4dae0`,
    lowImpact: `#ECF2FA`,
    buttonGrey: `#E1E8F5`,
    darkerGreyBackground: `#ECF2FA`,
    lightBackground: `#F5F9FF`,
    trendGrey: `#e9edf4`,
    lightestBackground: `#FAFCFF`,
    white: `#FFFFFF`,
    appNavBackground: `#101820`,
    borderColor: `rgba(0,31,86,0.07)`
  },
  dark: {
    brand: `#3039b2`,
    primary: `#4040DC`,
    error: `#EF4552`,
    orange: `#F68921`,
    warning: `#F2CC0F`,
    success: `#36b0a7`,
    accent: `#5043F9`,
    black: `#ffffff`,
    darkGrey: `#898B91`,
    middleGrey: `#5B5D66`,
    lighterGrey: `#5B5D66`,
    inputBorder: `#2E3442`,
    lowImpact: `#1A1E2C`,
    buttonGrey: `#212637`,
    darkerGreyBackground: `#151A27`, // subbed because aaron left it out
    lightBackground: `#151A27`,
    trendGrey: `#2E3442`,
    lightestBackground: `#151A27`,
    white: `#1C202F`,
    appNavBackground: `#101820`,
    borderColor: `rgba(255,255,255,0.04)`
  },
  otf: {
    swapBranding: `otf`,
    invertToLight: true,
    brand: `#ff5b00`,
    primary: `#ff5b00`,
    error: `#EF4552`,
    orange: `#F68921`,
    warning: `#F2CC0F`,
    success: `#36b0a7`,
    accent: `#5043F9`,
    black: `#101820`,
    darkGrey: `#313341`,
    middleGrey: `#444354`,
    lighterGrey: `#767582`,
    inputBorder: `#d4dae0`,
    lowImpact: `#ECF2FA`,
    buttonGrey: `#E1E8F5`,
    darkerGreyBackground: `#ECF2FA`,
    lightBackground: `#F5F9FF`,
    trendGrey: `#e9edf4`,
    lightestBackground: `#FAFCFF`,
    white: `#FFFFFF`,
    appNavBackground: `#ff5b00`,
    borderColor: `rgba(0,31,86,0.07)`
  }
};

export default THEMES;
