'use strict';

import { SET_LOGIN_DETAILS } from './index.js';

export function setLoginDetails(me, scope, user_id, is_owner, can_edit, avatar, client_name, client_id, active_plan, trial_days_remaining, active_themes = [], default_site_path = ``) {
  active_plan = true;

  return {
    type: SET_LOGIN_DETAILS,
    payload: {
      me,
      scope,
      is_owner,
      can_edit,
      user_id,
      avatar,
      client_name,
      client_id,
      active_plan,
      trial_days_remaining,
      active_themes,
      default_site_path
    }
  };
}
