'use strict';

export const POINT_META_CHANGED = `EVENT_POINT_META_CHANGED`;
export const POINT_CHANGE_REQUESTED = `EVENT_POINT_CHANGE_REQUESTED`;
export const POINT_CHANGE_PUSHED = `EVENT_POINT_CHANGE_PUSHED`;
export const POINT_CHANGE_FAILED = `EVENT_POINT_CHANGE_FAILED`;
export const EQUIP_META_CHANGED = `EVENT_EQUIP_META_CHANGED`;
export const SITE_META_CHANGED = `EVENT_SITE_META_CHANGED`;

export const emitEvent = (event_handle, data) => {
  let event = new CustomEvent(event_handle, {
    detail: data
  });

  window.dispatchEvent(event);
};

export const listenForEvent = (event_handle, callback, once = false) => {
  if (once) {
    window.addEventListener(event_handle, callback, { once });
  } else {
    window.addEventListener(event_handle, callback);
  }
};

export const removeEventListener = (event_handle, callback) => {
  window.removeEventListener(event_handle, callback);
};
