"use strict";

import React from "react";
import { connect } from "react-redux";
import { Link, NavLink, withRouter } from "react-router-dom";
import { setAuthData } from "../../actions/setAuthData.js";
import { getNavItems } from "../../helpers/NavHelper.js";
import { getActiveTheme } from "../../helpers/themes/ThemeHelper.js";
import Help from "../../svgs/Help.jsx";
import Logo from "../../svgs/Logo.jsx";
import OTF from "../../svgs/OTF.jsx";
import Close from "../../svgs/Close.jsx";
import Settings from "../../svgs/Settings.jsx";
import Logout from "../../svgs/Logout.jsx";
import Users from "../../svgs/Users.jsx";

class AppNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileNavShown: false,
    };
  }

  componentDidMount() {
    window.showMobileNavigation = () => this.setState({ mobileNavShown: true });

    if (window.Headway)
      window.Headway.init({
        selector: `.headway-anchor`,
        trigger: `.headway-trigger`,
        position: {
          x: `right`,
          y: `top`,
        },
        account: `xazew7`,
      });
  }

  closeNav() {
    this.setState({
      mobileNavShown: false,
    });

    return true;
  }

  render() {
    const { logout } = this.props,
      mainNav = (
        <ul>
          {getNavItems().map((n, i) => (
            <li key={i}>
              <NavLink
                location={this.props.location}
                to={n.uri}
                onClick={this.closeNav.bind(this)}
                activeClassName="active"
              >
                <span>{n.icon}</span>
                <span className="label">{n.label}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      ),
      utilityNav = (
        <ul className="util">
          <li>
            <a className="headway-trigger">
              <span className="headway-anchor" />
              <span className="label">Latest Changes</span>
            </a>
          </li>
          {this.props.features.iam && (
            <li>
              <NavLink
                location={this.props.location}
                to="/users"
                onClick={this.closeNav.bind(this)}
                activeClassName="active"
              >
                <span>{Users}</span>
                <span className="label">Users</span>
              </NavLink>
            </li>
          )}
          <li>
            <a href="http://fsg.sb/chariot" target="_blank">
              <span className="img-wrapper">{Help}</span>
              <span className="label">Help</span>
            </a>
          </li>
          <li>
            <NavLink
              location={this.props.location}
              to="/settings"
              onClick={this.closeNav.bind(this)}
              activeClassName="active"
            >
              <span>
                <span className="img-wrapper">
                  <img
                    src={
                      this.props.login.avatar ||
                      `https://gravatar.com/avatar/0?d=mp`
                    }
                  />
                  <span className="overlay">{Settings}</span>
                </span>
              </span>
              <span className="label">
                {this.props.login.user}
                <small>{this.props.login.client_name}</small>
              </span>
            </NavLink>
          </li>
          <li>
            <a onClick={() => this.closeNav() && logout()}>
              <span>{Logout}</span>
              <span className="label">Logout</span>
            </a>
          </li>
        </ul>
      );

    return (
      <aside
        className={
          this.state.mobileNavShown ? `mobile-visible` : `mobile-invisible`
        }
      >
        <nav>
          <Link className="logo" to="/">
            <span>
              {getActiveTheme(this.props.theme).swapBranding == `otf`
                ? OTF
                : Logo}
            </span>
            <span className="label">Chariot</span>
          </Link>
          <a className="close" onClick={this.closeNav.bind(this)} title="Close">
            {Close}
          </a>
          {mainNav}
          {utilityNav}
        </nav>
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({
  features: state.features,
  login: state.login,
  theme: state.settings.global_settings.theme,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(setAuthData()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppNav));
