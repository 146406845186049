/* eslint-disable no-console */
'use strict';

import store from './StoreHelper.js';
import { $ } from './ApiHelper.js';
import { 
  emitEvent, 
  POINT_CHANGE_PUSHED, 
  POINT_CHANGE_REQUESTED,
  POINT_CHANGE_FAILED,
  SITE_META_CHANGED, 
  EQUIP_META_CHANGED, 
  POINT_META_CHANGED 
} from './EventHelper.js';
import { getBestName } from './ApiNameHelper.js';
import { sortDesc, sortAsc } from './SortHelper.js';

const fallback_weather_response = { apparentTemperature: 0, cloudCover: 0, dewPoint: 0, humidity: 0, icon: `clear-day`, nearestStormBearing: 0, nearestStormDistance: 0, ozone: 0, precipIntensity: 0, precipProbability: 0, pressure: 0, summary: `Clear Day`, temperature: 0, time: 0, uvIndex: 0, visibility: 0, windBearing: 0, windGust: 0, windSpeed: 0 };

export const genericGet = (url, api, params) => {
  return new Promise((success, error) => $({ url, params, api, success, error }));
};

export const getSiteList = (includeStatus = true, include_inactive = false) => {
  return new Promise((resolve, reject) => {
    Promise.all([
      new Promise((resolveInner, rejectInner) => {
        $({
          params: {
            page_size: 9999999,
            include_inactive
          },
          api: `sites`,
          failure: `Failed to retrieve site list.`,
          success: ({ results }) => resolveInner(results),
          error: rejectInner
        });
      }),
      new Promise((resolveInner, rejectInner) => {
        if (!includeStatus) return resolveInner([]);

        $({
          url: `/status`,
          params: {
            page_size: 99999999
          },
          api: `stasis`,
          failure: `Failed to retrieve site statuses.`,
          success: ({ results }) => resolveInner(results),
          error: rejectInner
        });
      })
    ]).then(([sites, status]) => {
      resolve(sites.map((site) => includeStatus ? ({
        ...site,
        status: `up`,
        status_change: Date.now(),
        ...status.find(({ site_id }) => site_id == site.id)
      }) : site));
    }).catch(reject);
  });
};

export const getSitePlatforms = (site_id) => {
  return new Promise((resolve, reject) => {
    $({
      params: {
        site_id
      },
      api: `platforms`,
      failure: `Failed to retrieve site platforms.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const getSiteLegacySchedules = (site_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/schedules/${ site_id }`,
      api: `director`,
      failure: `Failed to retrieve schedules.`,
      success: resolve,
      error: reject
    });
  });
};

export const getSitePointValues = (site_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/sites`,
      params: {
        site_ids: site_id
      },
      api: `director`,
      failure: `Failed to retrieve site point values.`,
      success: (points) => resolve(points[site_id].points),
      error: reject
    });
  });
};

export const getSiteStatus = (site_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/status`,
      params: {
        site_ids: site_id
      },
      api: `stasis`,
      failure: `Failed to retrieve site status.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const getDownPoints = (site_id, equipment_id, point_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/points`,
      api: `stasis`,
      params: {
        site_id,
        equipment_id,
        point_id,
        page_size: 9999
      },
      failure: `Failed to retrieve down points.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const getSitesWithDownPoints = () => {
  return new Promise((resolve, reject) => {
    $({
      url: `/points/sites`,
      api: `stasis`,
      failure: `Failed to retrieve sites with down points.`,
      success: resolve,
      error: reject
    });
  });
};

export const getSite = (site_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/${ site_id }`,
      params: {
        recursive: true
      },
      api: `sites`,
      failure: `Failed to retrieve site.`,
      success: resolve,
      error: reject
    });
  });
};

export const getEquipment = (equipment_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/${ equipment_id }`,
      api: `equipment`,
      failure: `Failed to retrieve equipment.`,
      success: resolve,
      error: reject
    });
  });
};

export const getEquipmentBySiteId = (site_id) => {
  return new Promise((resolve, reject) => {
    $({
      api: `equipment`,
      params: {
        site_id
      },
      failure: `Failed to retrieve equipment.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const getEquipmentPointValues = (equipment_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/equipment/${ equipment_id }`,
      api: `director`,
      failure: `Failed to retrieve equipment point values.`,
      success: (points) => resolve(points[equipment_id].points),
      error: reject
    });
  });
};

export const getPointsByEquipmentId = (equipment_id) => {
  return new Promise((resolve, reject) => {
    getEquipment(equipment_id).then((equipment) => resolve(equipment.points)).catch(reject);
  });
};

export const getEntityStub = (key, value, extend) => {
  return new Promise((resolve, reject) => {
    $({
      api: `stub`,
      params: {
        [key]: value,
        extend
      },
      failure: `Failed to receive stub.`,
      success: resolve,
      error: reject
    });
  });
};

export const getSiteNotifications = (site_id, max_count, created_after, page = 1, notifications = [], resolveParent, rejectParent) => {
  return new Promise((resolve, reject) => {
    $({
      params: {
        site_id,
        count: max_count && max_count < 1000 ? max_count : 1000,
        page: page,
        from_date: created_after ? (new Date(created_after)).toISOString().replace(/\.\d{3}Z/, ``) : null
      },
      api: `notifications`,
      failure: `Failed to retrieve site notifications.`,
      success: ({ results, total_count }) => {
        let modified = results.filter((r) => {
            if (!created_after) return true;

            let d = +(new Date(r.created_at)),
              e = +(new Date(created_after));

            return d > e;
          }), total = notifications.concat(modified);

        max_count = max_count || total_count;

        if (total.length < total_count && total.length < max_count && modified.length > 0) {
          getSiteNotifications(site_id, max_count, created_after, page + 1, total, resolveParent || resolve, rejectParent || reject);
        } else {
          (resolveParent || resolve)(total.sort(sortDesc(`time_sent`)).slice(0, max_count));
        }
      },
      error: rejectParent || reject
    });
  });
};

export const getTimezoneData = (lat, lng) => {
  return new Promise((resolve) => {
    $({
      url: `&lat=${ lat }&lng=${ lng }`,
      api: `timezone`,
      failure: `Failed to retrieve site timezone data.`,
      success: (data) => resolve(data),
      error: () => resolve({ status: `OK`, message: ``, abbreviation: `CDT`, gmtOffset: -18000, dst: `1` })
    });
  });
};

export const getWeatherData = (lat, lng, fromDate, toDate) => {
  return new Promise((resolve, reject) => {
    $({
      api: `weather`,
      params: {
        lat,
        lng,
        fromDate,
        toDate
      },
      failure: `Failed to retrieve weather data.`,
      success: (data) => {
        if (!fromDate && !toDate) {
          resolve({
            humidity: `${ data.humidity }%`,
            temp: `${ data.temp }`,
            desc: `${ data.desc }`,
            icon: `${ data.icon }`
          });
        } else {
          resolve(data);
        }
      },
      error: reject
    });
  });
};

export const getRules = (page = 1, rules = [], resolveParent) => {
  return new Promise((resolve) => {
    $({
      params: {
        count: 100,
        page
      },
      api: `rules`,
      failure: `Failed to retrieve rules.`,
      success: ({ results, total_count }) => {
        let total = rules.concat(results);

        if (total.length < total_count) {
          getRules(page + 1, total, resolveParent || resolve);
        } else {
          (resolveParent || resolve)(total);
        }
      },
      error: () => (resolveParent || resolve)(rules.concat([]))
    });
  });
};

export const getUserList = (page, page_size = 9999) => {
  return new Promise((resolve, reject) => {
    $({
      api: `users`,
      params: {
        page,
        page_size
      },
      failure: `Failed to retrieve user list.`,
      success: (data) => resolve({ ...data, results: data.results.sort(sortAsc(`created_at`)) }),
      error: reject
    });
  });
};

export const getUserData = (user_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/${ user_id }`,
      api: `users`,
      failure: `Failed to retrieve user data.`,
      success: resolve,
      error: reject
    });
  });
};

export const saveExistingUser = (user_id, scope, name, email, can_edit) => {
  return new Promise((resolve, reject) => { 
    $({
      url: `/${ user_id }`,
      api: `users`,
      type: `PUT`,
      failure: `Failed to update user.`,
      succeeded: `Successfully updated user.`,
      body: { scope, name, email, can_edit, enabled: true },
      success: resolve,
      error: reject
    });
  });
};

export const saveNewUser = (scope, name, email, can_edit) => {
  return new Promise((resolve, reject) => { 
    $({
      api: `users`,
      type: `POST`,
      failure: `Failed to create new user.`,
      succeeded: `Successfully created new user.`,
      body: { scope, name, email, can_edit, enabled: true },
      success: resolve,
      error: reject
    });
  });
};

export const getSiteStructure = (site_id, get_values = store.getState().features.controls) => {
  return new Promise((resolve, reject) => {
    Promise.all([
      getSite(site_id),
      getSiteStatus(site_id),
      get_values ? getSitePointValues(site_id) : (new Promise((resolve) => resolve(fallback_weather_response)))
    ]).then(([site, [status], pointValues]) => {
      /* eslint-disable */
      if (!(site.latitude && site.longitude)) console.error(`This site is missing latitude and longitude data. Default values will be substituted.`);
      /* eslint-enable */

      Promise.all(site.latitude && site.longitude ? [
        getTimezoneData(site.latitude, site.longitude),
        new Promise((r) => getWeatherData(site.latitude, site.longitude).then(r).catch(() => r(fallback_weather_response)))
      ] : [
        new Promise((r) => r(fallback_weather_response)),
        new Promise((r) => r({ abbreviation: `CDT`, dst: `1`, gmtOffset: -18000, message: ``, status: `OK` }))
      ]).then(([timezone, weather]) => {
        resolve({
          ...site,
          status: `up`,
          status_change: Date.now(),
          ...status,
          timezone,
          weather,
          equipment: site.equipment.sort(sortDesc(`name`, (name, e) => getBestName(e))).map((equipment) => ({
            ...equipment,
            points: equipment.points.map((point) => ({
              ...point,
              status: `down`,
              status_last_failed: Date.now() / 1000,
              value: null,
              ...pointValues[point.id]
            })).sort((a, b) => {
              if (getBestName(a) > getBestName(b)) return 1;
              if (getBestName(a) < getBestName(b)) return -1;
              return 0;
            })
          })).sort((a, b) => {
            if (getBestName(a) > getBestName(b)) return 1;
            if (getBestName(a) < getBestName(b)) return -1;
            return 0;
          })
        });
      }).catch(reject);
    }).catch(reject);
  });
};

export const setPoint = (site_id, equip_id, point_id, value) => {
  return new Promise((resolve, reject) => {
    emitEvent(POINT_CHANGE_REQUESTED, { site_id, equip_id, point_id, value, timestamp: Date.now() });

    $({
      url: `/points`,
      api: `director`,
      type: `POST`,
      body: {
        [point_id]: value
      },
      succeeded: `Successfully set point.`,
      failure: `Failed to set point.`,
      success: (data) => {
        if (data[point_id].success) {
          emitEvent(POINT_CHANGE_PUSHED, { site_id, equip_id, point_id, value, timestamp: data[point_id].timestamp });

          resolve(data);
        } else {
          emitEvent(POINT_CHANGE_FAILED, { site_id, equip_id, point_id });
          
          reject();
        }
      },
      error: () => {
        emitEvent(POINT_CHANGE_FAILED, { site_id, equip_id, point_id });
        reject();
      }
    });
  });
};


export const setMultiplePoints = (site_id, equip_id, points) => {
  return new Promise((resolve, reject) => {
    for(const point_id in points) {
      emitEvent(POINT_CHANGE_REQUESTED, { site_id, equip_id, point_id, value: points[point_id], timestamp: Date.now() });
    }
    $({
      url: `/points`,
      api: `director`,
      type: `POST`,
      body: points,
      succeeded: `Successfully set point.`,
      failure: `Failed to set point.`,
      success: (data) => {
        try {

          for(const point_id in points) {
            if (data[point_id].success) {
              emitEvent(POINT_CHANGE_PUSHED, { site_id, equip_id, point_id, value: data[point_id].value, timestamp: data[point_id].timestamp });

              resolve(data);
            } else {
              emitEvent(POINT_CHANGE_FAILED, { site_id, equip_id, point_id });
              
              reject();
            }
          }
        } catch (e) {
          console.error(e);
        }
      },
      error: () => {
        for(const point_id in points) {
          emitEvent(POINT_CHANGE_FAILED, { site_id, equip_id, point_id });
        }
        reject();
      }
    });
  });
};

export const createRule = (body) => {
  return new Promise((resolve, reject) => {
    $({
      type: `POST`,
      api: `rules`,
      body,
      succeeded: `Successfully created new rule.`,
      failure: `Failed to create new rule.`,
      success: resolve,
      error: reject
    });
  });
};

export const updateRule = (rule_id, body) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/${ rule_id }`,
      type: `PUT`,
      api: `rules`,
      body,
      succeeded: `Successfully updated rule.`,
      failure: `Failed to update rule.`,
      success: resolve,
      error: reject
    });
  });
};

export const deleteRule = (rule_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/${ rule_id }`,
      type: `DELETE`,
      api: `rules`,
      succeeded: `Successfully deleted rule.`,
      failure: `Failed to delete rule.`,
      success: resolve,
      error: reject
    });
  });
};

export const updateEquipmentMeta = (body) => {
  return new Promise((resolve, reject) => {
    if (!body.id) return reject();
    let id = body.id;
    delete body.id;

    $({
      url: `/${ id }`,
      api: `equipment`,
      type: `PATCH`,
      body: body,
      succeeded: `Successfully updated equipment.`,
      failure: `Failed to update equipment.`,
      success: (data) => {
        emitEvent(EQUIP_META_CHANGED, { ...body, id });

        resolve(data);
      },
      error: reject
    });
  });
};

export const updatePointMeta = (body) => {
  return new Promise((resolve, reject) => {
    if (!body.id) return reject();
    let id = body.id;
    delete body.id;

    $({
      url: `/${ id }`,
      api: `points`,
      type: `PATCH`,
      body: body,
      succeeded: `Successfully updated point.`,
      failure: `Failed to update point.`,
      success: (data) => {
        emitEvent(POINT_META_CHANGED, { ...body, id });

        resolve(data);
      },
      error: reject
    });
  });
};

export const updateSiteMeta = (body, site) => {
  return new Promise((resolve, reject) => {
    $({
      api: `sites`,
      type: `PUT`,
      body: {
        id: site.id,
        client_id: site.client_id,
        created_at: site.created_at,
        is_legacy: site.is_legacy,
        latitude: site.latitude,
        locality: site.locality,
        name: site.name,
        postal_code: site.postal_code,
        region: site.region,
        street_line_1: site.street_line_1,
        status: site.status,
        status_change: site.status_change,
        site_id: site.site_id,
        display_name: site.display_name,
        ...body
      },
      succeeded: `Successfully updated site.`,
      failure: `Failed to update site.`,
      success: (data) => {
        emitEvent(SITE_META_CHANGED, body);

        resolve(data);
      },
      error: reject
    });
  });
};

export const saveSiteLegacySchedules = (site_id, body) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/schedules/${ site_id }`,
      api: `director`,
      type: `PUT`,
      body,
      succeeded: `Successfully updated schedules.`,
      failure: `Failed to update schedules.`,
      success: resolve,
      error: reject
    });
  });
};

export const getPointSnapshots = (start, end, point_id, count, page = 1, res, rej, accumulation = []) => {
  return new Promise((resolve, reject) => {
    $({
      params: {
        from_date: start.toISOString(),
        to_date: end.toISOString(),
        page_size: 5000,
        count,
        point_id,
        page
      },
      api: `snapshots`,
      failure: `Failed to retrieve point snapshots.`,
      success: ({ next_page, results }) => {
        let total = accumulation.concat(results);

        if (next_page) {
          getPointSnapshots(start, end, point_id, count, page + 1, res || resolve, rej || reject, total);
        } else {
          (res || resolve)(total);
        }
      },
      error: rej || reject
    });
  });
};

export const getPointStopwatch = (point_id, from_date, to_date, period, timezone, work_hours) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/stopwatch`,
      params: {
        point_id,
        from_date,
        to_date,
        period,
        timezone,
        work_hours
      },
      api: `reports`,
      failure: `Failed to retrieve stopwatch data.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const getCalculationGroup = (from_date, to_date, point_ids, period) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/stats`,
      params: {
        point_id: point_ids.join(`,`),
        from_date,
        to_date,
        period
      },
      api: `reports`,
      failure: `Failed to retrieve calculation group.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const getSiteReport = (base, point_labels, site_labels, site_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/sites`,
      body: {
        base,
        point_labels,
        site_labels,
        site_id
      },
      api: `reports`,
      type: `POST`,
      failure: `Failed to retrieve site report.`,
      silent_success: true,
      success: resolve,
      error: reject
    });
  });
};

export const getEquipmentReport = (base, point_labels, site_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/equipment`,
      body: {
        base,
        point_labels,
        site_id
      },
      api: `reports`,
      type: `POST`,
      failure: `Failed to retrieve equipment report.`,
      silent_success: true,
      success: resolve,
      error: reject
    });
  });
};

export const getSiteCostReport = (timeframe, site_labels) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/sites/cost`,
      body: {
        timeframe,
        site_labels
      },
      api: `reports`,
      type: `POST`,
      failure: `Failed to retrieve site cost report.`,
      silent_success: true,
      success: resolve,
      error: reject
    });
  });
};

export const getSiteComparisonReport = (base, point_labels, ranges, site_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/compare/site`,
      body: {
        base,
        point_labels,
        ranges,
        site_id
      },
      api: `reports`,
      type: `POST`,
      failure: `Failed to retrieve site comparison report.`,
      silent_success: true,
      success: resolve,
      error: reject
    });
  });
};

export const getSiteEnergyCostReport = (base, ranges, site_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/compare/site/energy`,
      body: {
        base,
        ranges,
        site_id
      },
      api: `reports`,
      type: `POST`,
      failure: `Failed to retrieve site energy cost report.`,
      silent_success: true,
      success: resolve,
      error: reject
    });
  });
};

export const getSavedReports = () => {
  return new Promise((resolve, reject) => {
    $({
      url: `/`,
      api: `saved-reports`,
      failure: `Failed to retrieve saved reports.`,
      success: resolve,
      error: reject
    });
  });
};

export const getAssetStatuses = () => {
  return new Promise((resolve, reject) => {
    $({
      url: `/assetstatuses`,
      params: {
        page_size: 999,
        client_id: store.getState().login.client_id
      },
      api: `ichnaea`,
      failure: `Failed to retrieve asset statuses.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const getAssetTypes = () => {
  return new Promise((resolve, reject) => {
    $({
      url: `/assettypes`,
      params: {
        page_size: 999,
        client_id: store.getState().login.client_id
      },
      api: `ichnaea`,
      failure: `Failed to retrieve asset types.`,
      success: ({ results }) => {
        Promise.all(results.map((type) => getCustomFieldsByAssetTypeId(type.id).then((custom_fields) => ({ ...type, custom_fields })))).then(resolve).catch(reject);
      },
      error: reject
    });
  });
};

export const getAssets = (site_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/assets`,
      params: {
        page_size: 999,
        expand: `personnel,customfields,attachments,comments`,
        site_id,
        client_id: store.getState().login.client_id
      },
      api: `ichnaea`,
      failure: `Failed to retrieve assets.`,
      success: ({ results }) => resolve(results.map((a) => ({ ...a, personnel: a.personnels }))), // TODO: remove this map
      error: reject
    });
  });
};

export const addAssetEntityComment = (id, comment, type = `assets`) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/${ type }/${ id }/comments`,
      api: `ichnaea`,
      type: `POST`,
      succeeded: `Successfully created new comment.`,
      failure: `Failed to create new comment.`,
      body: { comment },
      success: resolve,
      error: reject
    });
  });
};

export const getManufacturers = () => {
  return new Promise((resolve, reject) => {
    $({
      url: `/manufacturers`,
      params: {
        page_size: 999,
        client_id: store.getState().login.client_id
      },
      api: `ichnaea`,
      failure: `Failed to retrieve manufacturers.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const getPersonnel = () => {
  return new Promise((resolve, reject) => {
    $({
      url: `/personnel`,
      params: {
        page_size: 999,
        client_id: store.getState().login.client_id
      },
      api: `ichnaea`,
      failure: `Failed to retrieve personnel.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const getVendors = () => {
  return new Promise((resolve, reject) => {
    $({
      url: `/vendors`,
      params: {
        page_size: 999,
        client_id: store.getState().login.client_id
      },
      api: `ichnaea`,
      failure: `Failed to retrieve vendors.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const getCoverages = () => {
  return new Promise((resolve, reject) => {
    $({
      url: `/coverages`,
      params: {
        page_size: 999,
        expand: `attachments`,
        client_id: store.getState().login.client_id
      },
      api: `ichnaea`,
      failure: `Failed to retrieve coverages.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const getScheduledMaintenances = () => {
  return new Promise((resolve, reject) => {
    $({
      url: `/scheduledmaintenances`,
      params: {
        page_size: 999,
        expand: `attachments`,
        client_id: store.getState().login.client_id
      },
      api: `ichnaea`,
      failure: `Failed to retrieve scheduled maintenances.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const getReminders = () => {
  return new Promise((resolve, reject) => {
    $({
      url: `/reminders`,
      params: {
        page_size: 999,
        expand: `attachments`,
        client_id: store.getState().login.client_id
      },
      api: `ichnaea`,
      failure: `Failed to retrieve reminders.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const getIchnaeaClient = (id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/clients/${ id }`,
      api: `ichnaea`,
      failure: `Failed to retrieve client.`,
      success: resolve,
      error: reject
    });
  });
};

export const getCustomFieldsByAssetTypeId = (asset_type_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/assets/customfields`,
      params: {
        page_size: 999,
        asset_type_id,
        client_id: store.getState().login.client_id
      },
      api: `ichnaea`,
      failure: `Failed to retrieve custom fields.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const createCustomField = (name, asset_type_id, type = `string`, entity_type = `assets`, client_id = store.getState().login.client_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/${ entity_type }/customfields`,
      api: `ichnaea`,
      type: `POST`,
      body: {
        name, 
        type,
        client_id,
        asset_type_id
      },
      succeeded: `Successfully created new custom field.`,
      failure: `Failed to create new custom field.`,
      success: resolve,
      error: reject
    });
  });
};

export const updateCustomField = (asset_type_id, id, name, type = `string`, entity_type = `assets`, client_id = store.getState().login.client_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/customfields`,
      api: `ichnaea`,
      type: `PUT`,
      body: {
        id,
        name, 
        type,
        client_id,
        entity_type,
        asset_type_id
      },
      succeeded: `Successfully updated custom field.`,
      failure: `Failed to update custom field.`,
      success: resolve,
      error: reject
    });
  });
};

export const deleteCustomField = (customfield_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/customfields/${ customfield_id }`,
      type: `DELETE`,
      api: `ichnaea`,
      succeeded: `Successfully deleted custom field.`,
      failure: `Failed to delete custom field.`,
      success: resolve,
      error: reject
    });
  });
};

export const updateCustomFieldValue = (entity_id, custom_field_id, value, entity_type = `assets`) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/${ entity_type }/${ entity_id }/customfields/${ custom_field_id }/value`,
      api: `ichnaea`,
      type: `POST`,
      body: {
        value
      },
      succeeded: `Successfully updated custom field value.`,
      failure: `Failed to update custom field value.`,
      success: resolve,
      error: reject
    });
  });
};

export const createIchnaeaClient = (id, is_active = true) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/clients`,
      type: `POST`,
      api: `ichnaea`,
      body: {
        id,
        is_active
      },
      succeeded: `Successfully created client.`,
      failure: `Failed to create client.`,
      success: resolve,
      error: reject
    });
  });
};

export const createStatus = (status, color, client_id = store.getState().login.client_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/assetstatuses`,
      type: `POST`,
      api: `ichnaea`,
      body: {
        status,
        color,
        client_id
      },
      succeeded: `Successfully created status.`,
      failure: `Failed to create status.`,
      success: resolve,
      error: reject
    });
  });
};

export const createAssetType = (name, description, client_id = store.getState().login.client_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/assettypes`,
      type: `POST`,
      api: `ichnaea`,
      body: {
        name,
        description,
        client_id
      },
      succeeded: `Successfully created asset type.`,
      failure: `Failed to create asset type.`,
      success: resolve,
      error: reject
    });
  });
};

export const createAssetStatus = (status, client_id = store.getState().login.client_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/assetstatuses`,
      type: `POST`,
      api: `ichnaea`,
      body: {
        status,
        client_id
      },
      succeeded: `Successfully created asset status.`,
      failure: `Failed to create asset status.`,
      success: resolve,
      error: reject
    });
  });
};

export const createManufacturer = (name, phone_number, email_address, active = true, tags = { }, client_id = store.getState().login.client_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/manufacturers`,
      type: `POST`,
      api: `ichnaea`,
      body: {
        name,
        phone_number,
        email_address,
        active,
        tags,
        client_id
      },
      succeeded: `Successfully created manufacturer.`,
      failure: `Failed to create manufacturer.`,
      success: resolve,
      error: reject
    });
  });
};

export const createPersonnel = (name, phone_number, email_address, global_manager = false, active = true, tags = { }, client_id = store.getState().login.client_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/personnel`,
      type: `POST`,
      api: `ichnaea`,
      body: {
        client_id,
        name,
        phone_number,
        email_address,
        global_manager,
        tags,
        active
      },
      succeeded: `Successfully created personnel.`,
      failure: `Failed to create personnel.`,
      success: resolve,
      error: reject
    });
  });
};

export const updatePersonnel = (id, name, phone_number, email_address, global_manager = false, active = true, tags = { }, client_id = store.getState().login.client_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/personnel`,
      type: `PUT`,
      api: `ichnaea`,
      body: {
        id,
        client_id,
        name,
        phone_number,
        email_address,
        global_manager,
        tags,
        active
      },
      succeeded: `Successfully updated personnel.`,
      failure: `Failed to update personnel.`,
      success: resolve,
      error: reject
    });
  });
};

export const createVendor = (name, phone_number, email_address, active = true, tags = { }, client_id = store.getState().login.client_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/vendors`,
      type: `POST`,
      api: `ichnaea`,
      body: {
        name,
        phone_number,
        email_address,
        active,
        tags,
        client_id
      },
      succeeded: `Successfully created vendor.`,
      failure: `Failed to create vendor.`,
      success: resolve,
      error: reject
    });
  });
};

export const createCoverage = (asset_id, name, start_date, end_date, cron_schedule, run_once_at, coverage_type, cost, maximum_value, vendor_id, description = ``, client_id = store.getState().login.client_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/coverages`,
      type: `POST`,
      api: `ichnaea`,
      body: {
        client_id,
        asset_id,
        name,
        description,
        start_date,
        end_date,
        cron_schedule,
        run_once_at,
        coverage_type,
        cost,
        maximum_value,
        vendor_id
      },
      succeeded: `Successfully created coverage.`,
      failure: `Failed to created coverage.`,
      success: resolve,
      error: reject
    });
  });
};

export const updateCoverage = (coverage_id, asset_id, name, start_date, end_date, cron_schedule, run_once_at, coverage_type, cost, maximum_value, vendor_id, description = ``, client_id = store.getState().login.client_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/coverages`,
      type: `PUT`,
      api: `ichnaea`,
      body: {
        id: coverage_id,
        client_id,
        asset_id,
        name,
        description,
        start_date,
        end_date,
        cron_schedule,
        run_once_at,
        coverage_type,
        cost,
        maximum_value,
        vendor_id
      },
      succeeded: `Successfully updated coverage.`,
      failure: `Failed to update coverage.`,
      success: resolve,
      error: reject
    });
  });
};

export const deleteCoverage = (coverage_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/coverages/${ coverage_id }`,
      type: `DELETE`,
      api: `ichnaea`,
      succeeded: `Successfully deleted coverage.`,
      failure: `Failed to delete coverage.`,
      success: resolve,
      error: reject
    });
  });
};

export const createScheduledMaintenance = (asset_id, name, start_date, end_date, cron_schedule, run_once_at, service_type, cost, vendor_id, description, client_id = store.getState().login.client_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/scheduledmaintenances`,
      type: `POST`,
      api: `ichnaea`,
      body: {
        client_id,
        name,
        asset_id,
        start_date,
        end_date,
        cron_schedule,
        run_once_at,
        service_type,
        cost,
        vendor_id,
        description
      },
      succeeded: `Successfully created scheduled maintenance.`,
      failure: `Failed to create scheduled maintenance.`,
      success: resolve,
      error: reject
    });
  });
};

export const updateScheduledMaintenance = (scheduledMaintenance_id, asset_id, name, start_date, end_date, cron_schedule, run_once_at, service_type, cost, vendor_id, description, client_id = store.getState().login.client_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/scheduledmaintenances`,
      type: `PUT`,
      api: `ichnaea`,
      body: {
        client_id,
        name,
        id: scheduledMaintenance_id,
        asset_id,
        start_date,
        end_date,
        cron_schedule,
        run_once_at,
        service_type,
        cost,
        vendor_id,
        description
      },
      succeeded: `Successfully updated scheduled maintenance.`,
      failure: `Failed to update scheduled maintenance.`,
      success: resolve,
      error: reject
    });
  });
};

export const deleteScheduledMaintenance = (scheduledMaintenance_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/scheduledmaintenances/${ scheduledMaintenance_id }`,
      type: `DELETE`,
      api: `ichnaea`,
      succeeded: `Successfully deleted scheduled maintenance.`,
      failure: `Failed to delete scheduled maintenance.`,
      success: resolve,
      error: reject
    });
  });
};

export const createReminder = (asset_id, name, description, reminder_type, cron_schedule, run_once_at, start_date, end_date, client_id = store.getState().login.client_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/reminders`,
      type: `POST`,
      api: `ichnaea`,
      body: {
        client_id,
        asset_id, 
        name, 
        description, 
        reminder_type, 
        cron_schedule, 
        run_once_at, 
        start_date, 
        end_date
      },
      succeeded: `Successfully created reminder.`,
      failure: `Failed to create reminder.`,
      success: resolve,
      error: reject
    });
  });
};

export const updateReminder = (reminder_id, asset_id, name, description, reminder_type, cron_schedule, run_once_at, start_date, end_date, client_id = store.getState().login.client_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/reminders`,
      type: `PUT`,
      api: `ichnaea`,
      body: {
        client_id,
        id: reminder_id,
        asset_id, 
        name, 
        description, 
        reminder_type, 
        cron_schedule, 
        run_once_at, 
        start_date, 
        end_date
      },
      succeeded: `Successfully updated reminder.`,
      failure: `Failed to update reminder.`,
      success: resolve,
      error: reject
    });
  });
};

export const deleteReminder = (reminder_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/reminders/${ reminder_id }`,
      type: `DELETE`,
      api: `ichnaea`,
      succeeded: `Successfully deleted reminder.`,
      failure: `Failed to delete reminder.`,
      success: resolve,
      error: reject
    });
  });
};

export const createAsset = (name, asset_type_id, description, site_id, asset_status_id, estimated_value, physical_tag, latitude, longitude, parent_id, client_id = store.getState().login.client_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/assets`,
      type: `POST`,
      api: `ichnaea`,
      body: {
        name,
        asset_type_id,
        description,
        site_id,
        asset_status_id,
        estimated_value,
        physical_tag,
        latitude,
        longitude,
        client_id,
        parent_id
      },
      succeeded: `Successfully created asset.`,
      failure: `Failed to create asset.`,
      success: resolve,
      error: reject
    });
  });
};

export const updateAsset = (id, name, asset_type_id, description, site_id, asset_status_id, estimated_value, physical_tag, latitude, longitude, client_id = store.getState().login.client_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/assets`,
      type: `PUT`,
      api: `ichnaea`,
      body: {
        id,
        name,
        asset_type_id,
        description,
        site_id,
        asset_status_id,
        estimated_value,
        physical_tag,
        latitude,
        longitude,
        client_id
      },
      succeeded: `Successfully updated asset.`,
      failure: `Failed to update asset.`,
      success: resolve,
      error: reject
    });
  });
};

export const deleteAsset = (id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/assets/${ id }`,
      type: `DELETE`,
      api: `ichnaea`,
      succeeded: `Successfully deleted asset.`,
      failure: `Failed to delete asset.`,
      success: resolve,
      error: reject
    });
  });
};

export const assignManufacturersToAsset = (asset_id, manufacturers) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/assets/${ asset_id }/manufacturers`,
      type: `PUT`,
      api: `ichnaea`,
      succeeded: `Successfully assigned manufacturer to asset.`,
      failure: `Failed to assign manufacturer to asset.`,
      body: manufacturers,
      success: resolve,
      error: reject
    });
  });
};

export const assignPersonnelToAsset = (asset_id, personnel) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/assets/${ asset_id }/personnels`, // TODO change to personnel
      type: `PUT`,
      api: `ichnaea`,
      succeeded: `Successfully assigned personnel to asset.`,
      failure: `Failed to assign personnel to asset.`,
      body: personnel,
      success: resolve,
      error: reject
    });
  });
};

export const assignVendorsToAsset = (asset_id, vendors) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/assets/${ asset_id }/vendors`,
      type: `PUT`,
      api: `ichnaea`,
      succeeded: `Successfully assigned vendors to asset.`,
      failure: `Failed to assign vendors to asset.`,
      body: vendors,
      success: resolve,
      error: reject
    });
  });
};

export const uploadAttachment = (entity_type, entity_id, file) => {
  return new Promise((resolve, reject) => {
    let data = new FormData();

    data.append(`file`, file);

    $({
      url: `/${ entity_type }/${ entity_id }/attachments`,
      type: `POST`,
      api: `ichnaea`,
      succeeded: `Successfully uploaded attachment.`,
      failure: `Failed to upload attachment.`,
      formData: data,
      success: resolve,
      error: reject
    });
  });
};

export const deleteAttachment = (attachment_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/attachments/${ attachment_id }`,
      type: `DELETE`,
      api: `ichnaea`,
      succeeded: `Successfully deleted attachment.`,
      failure: `Failed to delete attachment.`,
      success: resolve,
      error: reject
    });
  });
};

export const createNewSite = (name, street_line_1, locality, region, postal_code) => {
  let body = { name };

  if (street_line_1) body.street_line_1 = street_line_1;
  if (locality) body.locality = locality;
  if (region) body.region = region;
  if (postal_code) body.postal_code = postal_code;

  return new Promise((resolve, reject) => {
    $({
      type: `PUT`,
      api: `sites`,
      body,
      succeeded: `Successfully created new site.`,
      failure: `Failed to create new site.`,
      success: resolve,
      error: reject
    });
  });
};

export const sendPaymentMethodToken = (source) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/payments/payment_methods`,
      type: `POST`,
      api: `gateway`,
      body: { source },
      succeeded: `Successfully sent payment method token.`,
      failure: `Failed to send payment method token.`,
      success: resolve,
      error: reject
    });
  });
};

export const getPaymentPlans = () => {
  return new Promise((resolve, reject) => {
    $({
      url: `/payments/plans`,
      api: `gateway`,
      failure: `Failed to retrieve payment plans.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const deleteSubscription = (subscription_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/payments/subscriptions/${ subscription_id }`,
      api: `gateway`,
      type: `DELETE`,
      succeeded: `Successfully deleted subscription.`,
      failure: `Failed to delete subscription.`,
      success: resolve,
      error: reject
    });
  });
};

export const createSubscription = (plans) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/payments/subscriptions`,
      api: `gateway`,
      type: `POST`,
      succeeded: `Successfully created subscription.`,
      failure: `Failed to create subscription.`,
      body: { plans },
      success: resolve,
      error: reject
    });
  });
};

export const getMe = () => {
  return new Promise((resolve, reject) => {
    $({
      url: `/me`,
      api: `gateway`,
      failure: `Failed to retrieve user data.`,
      success: resolve,
      error: reject
    });
  });
};

export const getSiteGroups = () => {
  return new Promise((resolve, reject) => {
    $({
      params: {
        page_size: 9999999
      },
      api: `site_groups`,
      failure: `Failed to retrieve site groups.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const getSiteLabels = () => {
  return new Promise((resolve, reject) => {
    $({
      params: {
        page_size: 9999999
      },
      api: `site_labels`,
      failure: `Failed to retrieve site labels.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const getEquipmentLabels = () => {
  return new Promise((resolve, reject) => {
    $({
      url: `/labels`,
      params: {
        page_size: 9999999
      },
      api: `equipment`,
      failure: `Failed to retrieve equipment labels.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const getPointLabels = () => {
  return new Promise((resolve, reject) => {
    $({
      url: `/labels`,
      params: {
        page_size: 9999999
      },
      api: `points`,
      failure: `Failed to retrieve point labels.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const createNewSiteGroup = (name) => {
  return new Promise((resolve, reject) => {
    $({
      api: `site_groups`,
      type: `POST`,
      body: { name },
      succeeded: `Successfully created new site group.`,
      failure: `Failed to create new site group.`,
      success: resolve,
      error: reject
    });
  });
};

export const getSitePresets = (site_id) => {
  return new Promise((resolve, reject) => {
    $({
      api: `presets`,
      params: {
        site_id
      },
      failure: `Failed to retrieve site presets.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const createNewPreset = (name, color, site_id) => {
  return new Promise((resolve, reject) => {
    $({
      api: `presets`,
      type: `POST`,
      body: {
        name,
        color,
        site_id
      },
      succeeded: `Successfully created new preset.`,
      failure: `Failed to create new preset.`,
      success: resolve,
      error: reject
    });
  });
};

export const updatePreset = (preset_id, body) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/${ preset_id }`,
      api: `presets`,
      type: `PATCH`,
      body,
      succeeded: `Successfully updated preset.`,
      failure: `Failed to update preset.`,
      success: resolve,
      error: reject
    });
  });
};

export const deletePreset = (preset_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/${ preset_id }`,
      api: `presets`,
      type: `DELETE`,
      succeeded: `Successfully deleted preset.`,
      failure: `Failed to delete preset.`,
      success: resolve,
      error: reject
    });
  });
};

export const getSiteSchedules = (site_id, type) => {
  return new Promise((resolve, reject) => {
    $({
      api: `schedules`,
      params: {
        site_id
      },
      failure: `Failed to retrieve site schedules.`,
      success: ({ results }) => resolve(results.filter((s) => s.type.toLowerCase() == type.toLowerCase())),
      error: reject
    });
  });
};

export const createNewSchedule = (name, timeZone, type, site_id, priority, rawDays, cloud = true) => {
  return new Promise((resolve, reject) => {
    $({
      api: `schedules`,
      type: `POST`,
      body: {
        name,
        timeZone,
        type,
        priority,
        rawDays,
        site_id,
        cloud
      },
      succeeded: `Successfully created new schedule.`,
      failure: `Failed to create new schedule.`,
      success: resolve,
      error: reject
    });
  });
};

export const updateSchedule = (schedule_id, body) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/${ schedule_id }`,
      api: `schedules`,
      type: `PUT`,
      body,
      succeeded: `Successfully updated schedule.`,
      failure: `Failed to update schedule.`,
      success: resolve,
      error: reject
    });
  });
};

export const deleteSchedule = (schedule_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/${ schedule_id }`,
      api: `schedules`,
      type: `DELETE`,
      succeeded: `Successfully deleted schedule.`,
      failure: `Failed to delete schedule.`,
      success: resolve,
      error: reject
    });
  });
};

export const updateUserSettings = (settings) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/user_settings`,
      api: `ambience`,
      type: `PUT`,
      body: settings,
      succeeded: `Successfully updated user settings.`,
      failure: `Failed to update user settings.`,
      success: resolve,
      error: reject
    });
  });
};

export const retrieveUserSettings = (token) => {
  return new Promise((resolve) => {
    $({
      url: `/user_settings`,
      api: `ambience`,
      failure: `Failed to retrieve user settings.`,
      success: resolve,
      token,
      error: () => resolve({
        client_settings: { },
        global_settings: { }
      })
    });
  });
};

export const retrieveSiteThings = (site_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/sites/${ site_id }/things`,
      api: `director`,
      failure: `Failed to retrieve site things.`,
      success: ({ results }) => resolve(results),
      error: reject
    });
  });
};

export const sendThingCommand = (identifier, command) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/things/${ identifier }/commands`,
      api: `director`,
      type: `POST`,
      body: {
        command
      },
      succeeded: `Successfully sent thing command.`,
      failure: `Failed to send thing command.`,
      success: resolve,
      error: reject
    });
  });
};

export const triggerEquipmentCreation = (identifier) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/things/${ identifier }/create_equipment`,
      api: `director`,
      type: `POST`,
      body: {
        data: { }
      },
      succeeded: `Successfully triggered equipment creation.`,
      failure: `Failed to trigger equipment creation.`,
      success: resolve,
      error: reject
    });
  });
};

export const exchangeMACAddress = (device_mac_address) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/token`,
      api: `commissioning`,
      type: `POST`,
      body: {
        device_mac_address
      },
      succeeded: `Successfully requested commissioning token.`,
      failure: `Failed to request commissioning token.`,
      success: resolve,
      error: reject
    });
  });
};

export const sendGatewayConfig = (config) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/sys/configure`,
      api: `physical-gateway`,
      type: `POST`,
      body: config,
      succeeded: `Successfully sent gateway configuration.`,
      failure: `Failed to send gateway configuration.`,
      success: resolve,
      error: reject
    });
  });
};

export const exitGatewayCommissioning = () => {
  return new Promise((resolve, reject) => {
    $({
      url: `/sys/exit`,
      api: `physical-gateway`,
      type: `POST`,
      succeeded: `Successfully exited provisioning.`,
      failure: `Failed to exit provisioning.`,
      success: resolve,
      error: reject
    });
  });
};

export const uploadAvatar = (file) => {
  return new Promise((resolve, reject) => {
    let data = new FormData();

    data.append(`file`, file);

    $({
      url: `/avatar`,
      type: `POST`,
      api: `gateway`,
      succeeded: `Successfully uploaded avatar.`,
      failure: `Failed to upload avatar.`,
      formData: data,
      success: resolve,
      error: reject
    });
  });
};

export const getFloorplans = (site_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/`,
      api: `floorplans`,
      params: {
        site_id
      },
      failure: `Failed to retrieve floorplans.`,
      success: resolve,
      error: reject
    });
  });
};

export const getFloorplan = (id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/${ id }`,
      api: `floorplans`,
      failure: `Failed to retrieve floorplans.`,
      success: resolve,
      error: reject
    });
  });
};

export const updateFloorplan = (id, body) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/${ id }`,
      type: `PATCH`,
      api: `floorplans`,
      body,
      failure: `Failed to update floorplan.`,
      success: resolve,
      error: reject
    });
  });
};

export const createNewFloorplan = (name, file, site_id) => {
  let data = new FormData();

  data.append(`file`, file);
  data.append(`name`, name);
  data.append(`site_id`, site_id);

  return new Promise((resolve, reject) => {
    $({
      url: `/`,
      api: `floorplans`,
      type: `POST`,
      succeeded: `Successfully uploaded floorplan.`,
      failure: `Failed to upload new floorplan.`,
      formData: data,
      success: resolve,
      error: reject
    });
  });
};

export const lookupRouter = (external_id) => {
  return new Promise((resolve, reject) => {
    $({
      url: `/clients/${ external_id }`,
      api: `ipam`,
      succeeded: `Successfully retrieved router.`,
      failure: `Failed to retrieve router.`,
      success: resolve,
      error: reject
    });
  });
};

export const lookupEquipmentByExternalId = (external_id) => {
  return new Promise((resolve, reject) => {
    $({
      params: {
        external_id
      },
      api: `equipment`,
      failure: `Failed to retrieve equipment for external id (${ external_id })`,
      success: (d) => resolve(d.results[0]),
      error: reject
    });
  });
};
