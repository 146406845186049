"use strict";

import store from "./StoreHelper.js";
import { setAuthData } from "../actions/setAuthData.js";
import { setLoginDetails } from "../actions/setLoginDetails.js";
import { setPermissions } from "../actions/setPermissions.js";
import handleRequestNotification from "./handleRequestNotification.js";
import { refreshUserSettingsCache } from "./SettingsSyncHelper.js";

let API_URLS = {
  none: ``,
  timezone: `https://vip.timezonedb.com/v2.1/get-time-zone?key=XXRWIIUM7RDR&format=json&by=position&fields=abbreviation,dst,gmtOffset`,
  gateway: `<GATEWAY>`,
  features: `<GATEWAY>/features`,
  users: `<GATEWAY>/users`,
  snapshots: `<GATEWAY>/reports/snapshots`,
  sites: `<GATEWAY>/sites`,
  site_groups: `<GATEWAY>/clients/site_groups`,
  site_labels: `<GATEWAY>/clients/site_labels`,
  equipment: `<GATEWAY>/atlas/equipment`,
  points: `<GATEWAY>/atlas/points`,
  platforms: `<GATEWAY>/atlas/platforms`,
  stub: `<GATEWAY>/atlas/stub`,
  rules: `<GATEWAY>/rules`,
  notifications: `<GATEWAY>/notifications`,
  stasis: `<GATEWAY>/stasis`,
  weather: `<GATEWAY>/weather`,
  director: `<GATEWAY>/director`,
  reports: `<GATEWAY>/reports`,
  presets: `<GATEWAY>/presets`,
  schedules: `<GATEWAY>/schedules`,
  ichnaea: `<GATEWAY>/asset-manager`,
  ambience: `<GATEWAY>/ambience`,
  commissioning: `<GATEWAY>/commissioning`,
  floorplans: `<GATEWAY>/floorplans/v2`,
  ipam: `<GATEWAY>/ipam`,
  embeddable: `<GATEWAY>/embeddable/token`,
  "saved-reports": `<GATEWAY>/saved-reports`,
  "physical-gateway": `https://setup-mmb.fsgchariot.app:8081`,
};

let plaintext_apis = [`physical-gateway`];

let lastRequestedRefreshedToken = 0;

const $ = (options, lastAttempt) => {
  const defaults = {
    url: ``,
    type: `GET`,
    params: {},
    body: {},
    success: () => {},
    error: () => {},
    api: `none`,
  };

  Object.keys(defaults).forEach((k) => {
    if (!options[k]) options[k] = defaults[k];
  });

  let url = API_URLS[options.api].replace(/<GATEWAY>/g, `/api`) + options.url;

  if (Object.keys(options.params).length > 0) {
    url += `?${Object.keys(options.params)
      .filter(
        (opt) =>
          options.params[opt] !== undefined && options.params[opt] !== null
      )
      .map((opt) => {
        return `${opt}=${options.params[opt]}`;
      })
      .join(`&`)}`;
  }

  let fetchOptions = {
    method: options.type,
    mode: `cors`,
    cache: `no-cache`,
    credentials: `omit`,
    headers: {},
    redirect: `follow`,
    referrerPolicy: `no-referrer`,
  };

  if (
    options.api !== `timezone` &&
    !Object.prototype.hasOwnProperty.call(options, `formData`)
  ) {
    fetchOptions.headers[`Content-Type`] =
      options.contentType || `application/json`;
  }

  let auth = store.getState().auth;

  if (options.token) {
    fetchOptions.headers[`Authorization`] = `Bearer ${options.token}`;
  } else if (auth.isAuthed && options.api !== `timezone`) {
    let auth = store.getState().auth;

    fetchOptions.headers[`Authorization`] = `Bearer ${auth.token}`;
  }

  if (Object.prototype.hasOwnProperty.call(options, `formData`)) {
    fetchOptions.body = options.formData;
  } else if (fetchOptions.method.toLowerCase() !== `get`) {
    fetchOptions.body = JSON.stringify(options.body);
  }

  window
    .fetch(url, fetchOptions)
    .then((response) => {
      if (!response.ok) {
        response.text().then((json) => {
          try {
            json = JSON.parse(json);
          } catch (e) /* eslint-disable */ {} /* eslint-enable */

          handleRequestNotification(response.status, json, options);

          if (response.status === 401) {
            if (lastAttempt) {
              store.dispatch(setAuthData());
              return;
            }

            return setTimeout(() => {
              $(options, true);
            }, 500);
          }

          if (response.status === 403) {
            if (store.getState().auth.isAuthed) {
              if (Date.now() - lastRequestedRefreshedToken > 10000) {
                lastRequestedRefreshedToken = Date.now();
                sendRefreshToken();
              }

              if (![`/login`, `/refresh`].includes(options.url)) {
                return setTimeout(() => {
                  $(options, true);
                }, 1000);
              }
            }
          }

          options.error({
            status: response.status,
            message: json.message ? json.message : json,
          });
        });
      } else {
        if (plaintext_apis.includes(options.api) || response.status == 204) {
          response.text().then((body) => {
            handleRequestNotification(response.status, body, options);

            options.success(body);
          });
        } else {
          response.json().then((body) => {
            handleRequestNotification(response.status, body, options);

            if ([`/login`, `/refresh`].includes(options.url)) {
              getSetAuthData(body.access_token, body.refresh_token);
            }

            options.success(body);
          });
        }
      }
    })
    .catch((error) => {
      /* eslint-disable */
      console.error(error);
      /* eslint-enable */

      options.error({});
    });
};

let SSOOverrideAuthToken = document.cookie.match(/OverrideAuthToken=([^;]+)/),
  SSOOverrideRefreshToken = document.cookie.match(
    /OverrideRefreshToken=([^;]+)/
  );

let sendRefreshToken = (
  callback = (a, r) => store.dispatch(setAuthData(a, r))
) => {
  /* eslint-disable */
  console.log(`Refreshing auth token`);
  /* eslint-enable */

  let auth = store.getState().auth;

  if (SSOOverrideAuthToken !== null && SSOOverrideRefreshToken !== null) {
    $({
      url: `/refresh`,
      api: `gateway`,
      type: `POST`,
      body: {
        refresh_token: SSOOverrideRefreshToken[1],
      },
      failure: `Failed to refresh auth token.`,
      succeeded: `Successfully refreshed auth token.`,
      success: (data) => {
        callback(data.access_token, data.refresh_token);

        let loc = window.location.hostname.split(`.`).slice(1, 3).join(`.`);
        document.cookie = `OverrideAuthToken=${data.access_token};domain=.${loc};path=/`;
        document.cookie = `OverrideRefreshToken=${data.refresh_token};domain=.${loc};path=/`;
      },
      error: () => store.dispatch(setAuthData()),
    });
  } else if (auth.isAuthed) {
    $({
      url: `/refresh`,
      api: `gateway`,
      type: `POST`,
      body: {
        refresh_token: auth.refreshToken,
      },
      failure: `Failed to refresh auth token.`,
      succeeded: `Successfully refreshed auth token.`,
      success: (data) => callback(data.access_token, data.refresh_token),
      error: () => store.dispatch(setAuthData()),
    });
  }
};

let getSetAuthData = (access_token, refresh_token) => {
  return new Promise((res) => {
    $({
      url: `/me`,
      api: `gateway`,
      token: access_token,
      failure: `Failed to retrieve user data.`,
      success: (data) => {
        let active_plan = true,
          active_trial,
          trial_days_remaining = 0;

        if (Object.prototype.hasOwnProperty.call(data, `payments`)) {
          active_plan =
            (data.payments.subscriptions.length > 0 &&
              data.payments.subscriptions.every(
                (s) => s.status == `trialing` || s.status == `active`
              )) ||
            data.user.scope == `admin`;
          active_trial = data.payments.subscriptions.find(
            (s) => s.trial_active && s.trial_days_left > 0
          );
        }

        if (active_trial) trial_days_remaining = active_trial.trial_days_left;

        res(data.permissions);

        if (window.hj) {
          window.hj(`identify`, data.user.id, {
            email: data.user.email,
            name: data.user.name,
          });
        }

        store.dispatch(setPermissions(data.permissions));
        store.dispatch(
          setLoginDetails(
            data.user.name,
            data.user.scope,
            data.user.id,
            data.user.is_owner,
            data.user.can_edit,
            data.avatar,
            data.client.name,
            data.client.id,
            active_plan,
            trial_days_remaining,
            (data.client.settings || {}).active_themes || [],
            (data.client.settings || {}).default_site_path || ``
          )
        );
        refreshUserSettingsCache(access_token);
        store.dispatch(setAuthData(access_token, refresh_token));
      },
    });
  });
};

export { $ };
