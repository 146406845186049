'use strict';

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import assets from './assets.js';
import auth from './auth.js';
import features from './features.js';
import login from './login.js';
import report from './report.js';
import site from './site.js';
import siteList from './siteList.js';
import settings from './settings.js';
import permissions from './permissions.js';

const persist = (name, reducer) => persistReducer({
  keyPrefix: `chariot->v2->`,
  key: name,
  storage
}, reducer);

export default combineReducers({
  assets: persist(`assets`, assets),
  auth: persist(`auth`, auth),
  features: persist(`features`, features),
  login: persist(`login`, login),
  report: persist(`report`, report),
  site: persist(`site`, site),
  siteList: persist(`siteList`, siteList),
  settings: persist(`settings`, settings),
  permissions: persist(`permissions`, permissions)
});
