'use strict';

import io from 'socket.io-client';
import store from './StoreHelper.js';

let connected = false, socket, authInterval, connectionAttempts = 0;

const connect = () => {
  return new Promise((resolve) => {
    if (socket) {
      if (connected) {
        return resolve(socket);
      } else {
        return setTimeout(() => connect().then((s) => resolve(s)), 1500);
      }
    }

    if (!store.getState().auth.token) {
      return setTimeout(() => connect().then((s) => resolve(s)), 1500);
    }

    if (connectionAttempts >= 10) return;
    connectionAttempts += 1;

    socket = io(window.location.origin, {
      path: `/pulse`,
      reconnection: true,
      reconnectionDelay: 4000,
      reconnectionDelayMax: 3000,
      reconnectionAttempts: 5
    });

    socket.on(`connected`, () => {
      authInterval = setInterval(() => {
        if (connected) return;

        let payload = JSON.stringify({
          access_token: store.getState().auth.token,
          chariot_host: window.location.hostname.toLowerCase()
        });

        socket.emit(`authorize`, payload);
      }, 1500);
    });

    socket.on(`authorized`, () => {
      connected = true;

      clearInterval(authInterval);
      resolve(socket);

      if (document.querySelector(`#disconnected`)) document.querySelector(`#disconnected`).className = `hidden`;
    });

    socket.on(`disconnect`, () => {
      connected = false;

      clearInterval(authInterval);
      socket.destroy();
      socket = null;
      connect().then((s) => resolve(s));

      if (document.querySelector(`#disconnected`)) document.querySelector(`#disconnected`).className = ``;
    });
  });
};

const subscribe = (socket, type, id) => {
  socket.emit(`subscribe`, `${ type }${ id && id.length > 0 ? `:${ id }` : `` }`);
};

const unsubscribe = (socket, type, id) => {
  socket.emit(`unsubscribe`, `${ type }${ id && id.length > 0 ? `:${ id }` : `` }`);
};

setInterval(() => {
  if (!connected && document.querySelector(`#disconnected`)) document.querySelector(`#disconnected`).className = ``;
}, 3000);

connect();

export { connect, subscribe, unsubscribe };
