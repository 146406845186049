'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill="none" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M10.1 5.4L5.9 3.3l-5.3 3v12.1l5.3-3 6.1 3 5.3-3v-2.2"/><path fill="none" stroke="currentColor" class="h" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M21.4 4.8c0 3.2-4.2 6-4.2 6S13 8 13 4.8C13 2.5 14.9.6 17.2.6c2.4 0 4.2 1.9 4.2 4.2z"/><circle stroke="currentColor" class="h" fill="none" cx="17.3" cy="4.8" r="1.4"/><path fill="none" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M5.9 3.3v12.1m6.1-4.8v7.8"/>`,
  {
    width: `22`,
    height: `19`
  }
);
/* eslint-enable */
