'use strict';

import { UPDATE_GLOBAL_SETTINGS } from './index.js';

export function updateGlobalSettings(obj, source = `local`) {
  return {
    type: UPDATE_GLOBAL_SETTINGS,
    payload: {
      obj, 
      source
    }
  };
}
