'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill="none" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M14.37 16.09c0-3.33-2.7-6.02-6.03-6.02H6.62C3.29 10.07.6 12.77.6 16.1"/><circle fill="none" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="7.48" cy="4.04" r="3.44"/><path fill="none" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M18.67 16.09c0-2.75-2.05-5.01-4.7-5.36"/><path fill="none" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M12.49 2.19c.42 0 .81.08 1.18.23 1.12.46 1.91 1.57 1.91 2.86 0 1.71-1.38 3.09-3.09 3.09-.36 0-.7-.06-1.02-.17"/>`,
  {
    width: `19.27`,
    height: `16.69`
  }
);
/* eslint-enable */
