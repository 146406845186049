'use strict';

import { 
  UPDATE_GLOBAL_SETTINGS,
  UPDATE_CLIENT_SETTINGS,
  SET_VIEW_DISCONNECT_MSG
} from '../actions';

const initialState = {
  updated_by: `local`,
  global_settings: {
    time_zone: `America/Chicago`,
    theme: `system`,
    noisy_requests: false,
    temperature_units: `F`,
  },
  client_settings: {
    landing_page: `/sites`,
    disconnected_message_hidden: false,
    favorite_sites: [],
    disable_simpleview: false
  }
};

export default function settings(state = initialState, action) {
  switch(action.type) {
  case UPDATE_GLOBAL_SETTINGS: 
    return {
      ...state,
      updated_by: action.payload.source,
      global_settings: {
        ...state.global_settings,
        ...action.payload.obj
      }
    };
  case UPDATE_CLIENT_SETTINGS: 
    return {
      ...state,
      updated_by: action.payload.source,
      client_settings: {
        ...state.client_settings,
        ...action.payload.obj
      }
    };
  case SET_VIEW_DISCONNECT_MSG:
    return {
      ...state,
      updated_by: `local`,
      client_settings: {
        ...state.client_settings,
        disconnected_message_hidden: !action.payload
      }
    };
  default: 
    return state;
  }
}
