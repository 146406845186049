'use strict';

const initialState = {
  account: true,
  assets: true,
  controls: true,
  curbtocar: false,
  dashboard: false,
  floorplans: true,
  health: true,
  iam: true,
  legacyschedules: true,
  massedit: false,
  nimitz: false,
  notifications: true,
  provisioning: false,
  reports: true,
  schedules: true,
  simpleview: false,
  sites: true
};

export default function features() {
  return { ...initialState };
}
