'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5px" d="M1.74 12.5h21.52M1.74 5.33h21.52M1.74 19.67h21.52"/>`,
  {
    width: `25`,
    height: `25`
  }
);
/* eslint-enable */
