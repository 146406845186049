'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill="none" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M17.1 13.9v3.2c0 1-.8 1.8-1.8 1.8H2.4c-1 0-1.8-.8-1.8-1.8V7L8.8.6l3.5 2.7"/><path fill="none" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M6.1 18.9V9.7h5.5v9.2"/><path d="M17.7 1.4l-3.3 5h5l-3.3 5" fill="none" stroke="#3958cd" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"/><path d="M17.7 1.4l-3.3 5h5l-3.3 5" fill="none" stroke="currentColor" class="h" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"/>`,
  {
    width: `20`,
    height: `19.5`,
  }
);
/* eslint-enable */
