'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<polyline class="h" stroke="currentColor" points="25.43 6.57 23.35 6.57 21.8 11.25 18.68 1.9 17.12 6.57 15.04 6.57" style="fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:1.2000000000000002px"/><path d="M11.49,3.89a2,2,0,0,0-1.78,0l-8,4A2,2,0,0,0,.6,9.68V19.2A2,2,0,0,0,1.7,21l8,4a2,2,0,0,0,1.79,0l8-4a2,2,0,0,0,1.11-1.79V18.07" stroke="currentColor" style="fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:1.2000000000000002px"/><polyline points="0.92 8.6 10.6 13.44 16.1 10.56" stroke="currentColor" style="fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:1.2000000000000002px"/><line x1="10.6" y1="25.2" x2="10.6" y2="13.44" stroke="currentColor" style="fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:1.2000000000000002px"/>`,
  {
    width: `26.03`,
    height: `25.81`
  }
);
/* eslint-enable */
