'use strict';

import { 
  SET_PERMISSIONS
} from '../actions';

const initialState = { };

export default function permissions(state = initialState, action) {
  switch(action.type) {
  case SET_PERMISSIONS:
    return {
      ...state,
      ...action.payload
    };
  default: 
    return state;
  }
}
