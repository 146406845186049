'use strict';

import { 
  SET_LOGIN_DETAILS
} from '../actions';

const initialState = {
  user: `Demo User`,
  scope: `client`,
  user_id: 0,
  avatar: ``,
  client_name: `FSG Chariot`,
  client_id: `null`,
  active_plan: true,
  trial_days_remaining: 0,
  active_themes: [],
  default_site_path: ``,
  is_owner: false,
  can_edit: true
};

export default function login(state = initialState, action) {
  switch(action.type) {
  case SET_LOGIN_DETAILS: 
    return {
      ...state,
      user: action.payload.me,
      scope: action.payload.scope,
      avatar: action.payload.avatar,
      user_id: action.payload.user_id,
      is_owner: action.payload.is_owner,
      can_edit: action.payload.can_edit,
      client_name: action.payload.client_name,
      client_id: action.payload.client_id,
      active_plan: action.payload.active_plan,
      trial_days_remaining: action.payload.trial_days_remaining,
      active_themes: action.payload.active_themes,
      default_site_path: action.payload.default_site_path
    };
  default: 
    return state;
  }
}
