'use strict';

import React from 'react';
import ReactDOM from 'react-dom';

import "regenerator-runtime/runtime.js";
import '../scss/global.scss';

import cssVars from 'css-vars-ponyfill';

import Root from './components/Root.jsx';

/* eslint-disable */
console.warn(`Loading Chariot version: ${ process.env.GIT_TAG }`);
/* eslint-enable */

if (`serviceWorker` in navigator) {
  window.addEventListener(`load`, function() {
    navigator.serviceWorker.register(`/service-worker.js?${ process.env.GIT_TAG }`).then(function(registration) {
      /* eslint-disable */
      console.log(`ServiceWorker registration successful with scope: `, registration.scope);
      /* eslint-enable */
    }, function(err) {
      /* eslint-disable */
      console.log(`ServiceWorker registration failed: `, err);
      /* eslint-enable */
    });
  });
}

ReactDOM.render(<Root />, document.getElementById(`app`));

cssVars({
  rootElement: document,
  shadowDOM: false,
  include: `link[rel=stylesheet],style`,
  exclude: ``,
  onlyLegacy: true,
  preserveStatic: true,
  preserveVars: false,
  silent: false,
  updateDOM: true,
  updateURLs: true,
  watch: true
});
