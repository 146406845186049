'use strict';

import { SET_AUTH_DATA } from './index.js';

export function setAuthData(token, refreshToken) {
  if (!token || !refreshToken) {
    let loc = window.location.hostname.split(`.`).slice(1,3).join(`.`);
    document.cookie = `OverrideAuthToken=;domain=.${ loc };path=/`;
    document.cookie = `OverrideRefreshToken=;domain=.${ loc };path=/`;
  }

  return {
    type: SET_AUTH_DATA,
    payload: {
      token,
      refreshToken,
      isAuthed: token && token.length > 0
    }
  };
}
