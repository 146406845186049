'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill="none" stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" d="M9.2 7.2l-5.1 5.7m10.4-1.8l-2.7-3.8m9.5-2l-4.4 5.6"/><circle fill="none" stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" cx="15.6" cy="12.6" r="2"/><circle cx="23.6" cy="2.6" r="2" fill="none" stroke="currentColor" class="h" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"/><circle fill="none" stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" cx="10.6" cy="5.6" r="2"/><circle fill="none" stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" cx="2.6" cy="14.6" r="2"/>`,
  {
    width: `26.2`,
    height: `17.2`
  }
);
/* eslint-enable */
