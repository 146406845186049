'use strict';

import React from 'react';
import store from '../StoreHelper.js';
import THEME_FOOTPRINT from './ThemeFootprint.js';
import THEMES from './themes.js';
import Color from 'color';

let processTheme = (theme) => {
  let styles = { };

  Object.keys(THEME_FOOTPRINT).forEach((k) => {
    let selector = THEME_FOOTPRINT[k];

    if (!Object.prototype.hasOwnProperty.call(theme, k) && !theme[k]) {
      let value = theme.invertToLight ? selector.lightValue : selector.darkValue;

      if (theme.bordersNoShadows && Object.prototype.hasOwnProperty.call(selector, `bnsValue`)) {
        value = selector.bnsValue;
      }

      styles[`--${ selector.name }`] = value;
      return;
    }

    let themeColor = theme[k];

    styles[`--${ selector.name }`] = themeColor;

    if (selector.variants && Object.prototype.hasOwnProperty.call(selector, `variants`)) {
      if (selector.variants && selector.variants.alpha && Object.prototype.hasOwnProperty.call(selector.variants, `alpha`)) {
        selector.variants.alpha.forEach((a) => {
          styles[`--${ selector.name }-alpha-${ a }`] = Color(themeColor).alpha(a / 100).hsl().string();
        });
      }

      if (selector.variants && selector.variants.adjust && Object.prototype.hasOwnProperty.call(selector.variants, `adjust`)) {
        selector.variants.adjust.forEach((a) => {
          if (theme.invertToLight) {
            styles[`--${ selector.name }-adjust-${ a }`] = Color(themeColor).lighten(a / 100).hsl().string();
          } else {
            styles[`--${ selector.name }-adjust-${ a }`] = Color(themeColor).darken(a / 100).hsl().string();
          }
        });
      }

      if (selector.variants && selector.variants.altAdjust && Object.prototype.hasOwnProperty.call(selector.variants, `altAdjust`)) {
        selector.variants.altAdjust.forEach((a) => {
          if (!theme.invertToLight) {
            styles[`--${ selector.name }-adjust-${ a }-alt`] = Color(themeColor).lighten(a / 100).hsl().string();
          } else {
            styles[`--${ selector.name }-adjust-${ a }-alt`] = Color(themeColor).darken(a / 100).hsl().string();
          }
        });
      }
    }
  });

  return styles;
};

let fallbackTheme = (theme) => {
  let active_themes = store.getState().login.active_themes;

  if (!active_themes || active_themes.length == 0) {
    active_themes = [`light`, `dark`, `system`];
  }

  if (active_themes.includes(theme)) {
    return theme;
  }

  return active_themes[0];
};

let wrapStyles = (styles) => {
  return `:root {${ Object.keys(styles).reduce((s, k) => `${ s }${ k }:${ styles[k] };`, ``) }}`;
};

let renderTheme = (theme) => (
  <style dangerouslySetInnerHTML={ { __html: wrapStyles(processTheme(getActiveTheme(theme))) } } />
);

let getRenderedTheme = (_theme) => {
  let theme = fallbackTheme(_theme);

  if (theme == `system`) {
    theme = window.matchMedia(`(prefers-color-scheme: dark)`).matches ? `dark` : `light`;
  }

  return theme;
};

let getActiveTheme = (_theme, process = false) => {
  let theme = getRenderedTheme(_theme), 
    ret = THEMES[theme];

  if (process) return processTheme(ret);
  return ret;
};

export { renderTheme, getRenderedTheme, getActiveTheme, THEMES };
