`use strict`;

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill="#fff" d="M17.27 34.54C7.73 34.54 0 26.81 0 17.27c0-3.71 1.19-7.31 3.4-10.29.35-.47 1.01-.57 1.48-.22s.57 1.01.22 1.48c-4.99 6.72-3.58 16.21 3.14 21.2 2.61 1.94 5.78 2.98 9.03 2.98.59.05 1.02.56.98 1.14-.05.53-.46.94-.98.98zM7.83 5.15c-.58 0-1.06-.47-1.06-1.06 0-.34.16-.66.44-.86C10.14 1.13 13.66-.01 17.27 0c.59.05 1.02.56.98 1.14-.04.52-.45.93-.98.98-3.16-.01-6.25.98-8.82 2.83-.18.13-.4.2-.62.2z"/><path fill="#fff" d="M17.27 23.99c-3.71-.12-6.62-3.22-6.5-6.92.11-3.54 2.96-6.39 6.5-6.5.58 0 1.06.47 1.06 1.06 0 .58-.47 1.06-1.06 1.06-2.54 0-4.6 2.06-4.59 4.6 0 2.54 2.06 4.59 4.59 4.59.59.05 1.02.56.98 1.14-.05.51-.46.93-.98.97z"/><path fill="#fff" d="M8.14 24.35c-.35 0-.67-.16-.88-.43-.2-.3-.38-.61-.55-.93C3.55 17.18 5.7 9.92 11.5 6.77c1.77-.96 3.76-1.46 5.77-1.45.58 0 1.06.47 1.06 1.06s-.47 1.06-1.06 1.06c-5.46.02-9.87 4.47-9.84 9.93.01 1.59.4 3.16 1.14 4.57.14.26.29.52.46.76.32.49.18 1.14-.3 1.47-.17.1-.38.17-.59.18zm9.13 4.91c-1.81 0-3.59-.4-5.22-1.2-.46-.22-.9-.46-1.33-.73-.49-.32-.63-.97-.31-1.46s.97-.63 1.46-.31c.35.22.71.42 1.08.6 1.34.65 2.81.99 4.3.98.58 0 1.06.47 1.06 1.06 0 .58-.47 1.06-1.06 1.06h.02z"/>`,
  {
    width: `18.33`,
    height: `34.54`
  }
);
/* eslint-enable */
