"use strict";

import React from "react";
import { Route } from "react-router-dom";
import Loader from "./loader.jsx";

const buildRoute = (name, path, exact = true) => (
  <Route
    exact={exact}
    key={name}
    path={`/${path !== undefined ? path : name}`}
    component={(props) => (
      <Loader load={() => import(`./${name}.jsx`)}>
        {(Component) => (Component == null ? null : <Component {...props} />)}
      </Loader>
    )}
  />
);

export default [
  buildRoute(`index`, ``),
  buildRoute(`login`),
  buildRoute(`login`, `reset`),
  buildRoute(`login`, `reset/:reset_id`),
  buildRoute(`login`, `register`),
  buildRoute(`dashboard`),
  buildRoute(`rules`, `rules`, false),
  buildRoute(`users`),
  buildRoute(`users`, `users/:user_id`),
  buildRoute(`trends`),
  buildRoute(`trendsAddPoint`, `trends/add`),
  buildRoute(`reports`, `reports/:report_id?`, false),
  buildRoute(`sites`),
  buildRoute(`newSite`, `sites/new`),
  buildRoute(`floorplan`, `sites/:site_id/floor`, true),
  buildRoute(`site`, `sites/:site_id`, false),
  buildRoute(`assets`),
  buildRoute(`siteAssets`, `assets/:site_id`, false),
  buildRoute(`health`),
  buildRoute(`healthDashboards`, `health/dashboards/:db_id`, false),
  buildRoute(`massedit`),
  buildRoute(`settings`, `settings`, false),
  buildRoute(`trial_ended`),
  buildRoute(`router`),
  <Route
    key={`notfound`}
    component={(props) => (
      <Loader load={() => import(`./notfound.jsx`)}>
        {(Component) => (Component == null ? null : <Component {...props} />)}
      </Loader>
    )}
  />,
];
