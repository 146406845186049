'use strict';

import SvgHelper from '../helpers/SvgHelper.jsx';

/* eslint-disable */
export default SvgHelper(
  `<path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16.75.75l-11 11-5-5"/>`,
  {
    width: `17.5`,
    height: `12.5`
  }
);
/* eslint-enable */
