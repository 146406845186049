'use strict';

import store from './StoreHelper.js';
import { updateUserSettings, retrieveUserSettings } from './AppDataHelper.js';
import { updateClientSettings } from '../actions/updateClientSettings.js';
import { updateGlobalSettings } from '../actions/updateGlobalSettings.js';

export const refreshUserSettingsCache = (access_token) => {
  retrieveUserSettings(access_token).then(({ client_settings, global_settings }) => {
    store.dispatch(updateClientSettings(client_settings, `api`));
    store.dispatch(updateGlobalSettings(global_settings, `api`));
  });
};

const sync = () => {
  let settings = JSON.stringify(store.getState().settings);

  store.subscribe(() => {
    if (JSON.stringify(store.getState().settings) !== settings) {
      settings = JSON.stringify(store.getState().settings);

      let temp = JSON.parse(settings);

      delete temp._persist;
      delete temp.updated_by;

      if (store.getState().settings.updated_by == `local`) updateUserSettings(temp);
    }
  });

  refreshUserSettingsCache();
};

export default sync;
