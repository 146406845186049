'use strict';

import React from 'react';
import LoadingScreen from '../components/utilities/LoadingScreen.jsx';

class Loader extends React.Component {
  state = {
    component: null,
    failed: false,
    loaded: false
  }

  componentDidMount() {
    this._mounted = true;

    this.props.load()
      .then((component) => {
        this._mounted && this.setState(() => ({
          component: component.default ? component.default : component,
          loaded: true
        })); 
      }).catch((e) => {
        /* eslint-disable */
        console.error(e);
        /* eslint-enable */
        this._mounted && this.setState({
          failed: true
        });
      });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    if (this.state.failed) {
      return (
        <LoadingScreen err />
      );
    }

    if (!this.state.loaded) {
      return (
        <LoadingScreen />
      );
    }

    return this.props.children(this.state.component);
  }
}

export default Loader;
