'use strict';

import React from 'react';
import { connect } from 'react-redux';
import * as atatus from 'atatus-spa';
import { Switch, withRouter } from 'react-router-dom';
import AppNav from './utilities/AppNav.jsx';
import MobileNav from './utilities/MobileNav.jsx';
import Close from '../svgs/Close.jsx';
import { setViewDisconnectMsg } from '../actions/setViewDisconnectMsg.js';
import routes from '../routes/routes.js';
import { renderTheme, getRenderedTheme } from '../helpers/themes/ThemeHelper.js';

import '../helpers/SocketHelper.js';

let parseQuery = (query = ``) => query.indexOf(`?`) !== 0 ? { } : query.split(`?`)[1].split(`&`).reduce((a, q) => {
  a[q.split(`=`)[0]] = q.split(`=`)[1];
  return a;
}, { });

const isUnauthedPage = (pathname) => {
  let paths = [`/login`, `/`, `/reset`, `/register`];

  if (paths.includes(pathname)) {
    return true;
  }

  let resetLinkExp = /^\/reset\/[\w|\d|-]+(\?.*)?$/;

  if (resetLinkExp.test(pathname)) {
    return true;
  }

  return false;
};

const isBillingPage = (pathname) => {
  let paths = [`/trial_ended`];

  if (paths.includes(pathname)) {
    return true;
  }

  return false;
};

class App extends React.Component {
  constructor(props) {
    super(props);

    document.documentElement.style.setProperty(`--vh`, `${ window.innerHeight * 0.01 }px`);

    this.state = {

    };
  }

  componentDidMount() {
    this.checkPath(this.props);

    window.addEventListener(`resize`, () => {
      document.documentElement.style.setProperty(`--vh`, `${ window.innerHeight * 0.01 }px`);
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.checkPath(nextProps);
  }

  componentDidCatch(error) {
    atatus.notify(error);
  }

  checkPath(props = this.props) {
    let fwd = encodeURIComponent(`${ props.location.pathname }${ props.location.search }`);

    if (parseQuery(props.location.search).r) {
      fwd = parseQuery(props.location.search).r;
    }

    let billing_available = !props.active_plan || props.trial_days_remaining > 0;

    if (!props.isAuthed && !isUnauthedPage(props.location.pathname)) {
      this.props.history.replace(`/login?r=${ fwd }`);
    } else if (props.isAuthed && !props.active_plan && !isBillingPage(props.location.pathname)) {
      props.history.push(`/trial_ended`);
    } else if ((props.isAuthed && isUnauthedPage(props.location.pathname)) || (!billing_available && isBillingPage(props.location.pathname))) {
      if (parseQuery(props.location.search).r) {
        props.history.replace(decodeURIComponent(parseQuery(props.location.search).r));
      } else {
        let landing_page = this.props.landing_page;

        if (landing_page == `/sites` && !this.props.features.sites) landing_page = `/dashboard`;
        if (landing_page == `/dashboard` && !this.props.features.dashboard) landing_page = `/trends`;
        if (landing_page == `/trends` && !this.props.features.reports) landing_page = `/settings`;

        props.history.replace(landing_page);
      }
    }
  }

  render() {
    let isLogin = isUnauthedPage(this.props.location.pathname) && !isBillingPage(this.props.location.pathname),
      isBilling = !isUnauthedPage(this.props.location.pathname) && isBillingPage(this.props.location.pathname);

    return (
      <main className={ `${ isLogin ? `login` : isBilling ? `billing` : `` } theme--${ getRenderedTheme(this.props.theme) } ` }>
        { renderTheme(this.props.theme) }
        {
          !isLogin && !isBilling && (
            <AppNav />
          )
        }
        {
          !isLogin && !isBilling && (
            <MobileNav />
          )
        }
        {
          !this.props.disconnected_message_hidden && (
            <a onClick={ () => window.location.reload(true) } id="disconnected" className="hidden"><span onClick={ (e) => {
              e.stopPropagation();
              e.preventDefault();
              this.props.hideDisconnectedMessage();
            } } className="dismiss" title="Close">{ Close }</span><p>You are not receiving realtime updates. Click here to reload the app and reconnect.</p></a>
          )
        }
        <Switch>
          { routes }
        </Switch>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthed: state.auth.isAuthed,
  active_plan: state.login.active_plan,
  trial_days_remaining: state.login.trial_days_remaining,
  landing_page: state.settings.client_settings.landing_page,
  theme: state.settings.global_settings.theme,
  disconnected_message_hidden: state.settings.client_settings.disconnected_message_hidden,
  features: state.features
});

const mapDispatchToProps = (dispatch) => ({
  hideDisconnectedMessage: () => dispatch(setViewDisconnectMsg(false))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
